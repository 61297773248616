import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const getClientFeatures = async (clientId, token) => {
  const url = new URL(`${baseUrl}/Clients/${clientId}/ClientFeatures`);

  let options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getClientLocationDetails = async (id, token) => {
  const url = new URL(`${baseUrl}/Clients/${id}`);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getClientScreenSettings = async (clientId, token) => {
  const url = new URL(`${baseUrl}/Clients/${clientId}/ScreenSettings`);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getDealerInformation = async (clientId, token) => {
  const url = new URL(
    `${baseUrl}/Clients/${clientId}/BlueBird/PbsDealer/GetAll`
  );

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};
export const getAccountInformation = async (clientId, token) => {
  const url = new URL(
    `${baseUrl}/Clients/${clientId}/BlueBird/BbAccount/GetAll`
  );

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const postDBRReportsConfiguration = async (data, clientId, token) => {
  const url = new URL(`${baseUrl}/Clients/${clientId}/BlueBird/PbsDealer`);

  let options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options)
    .then((response) => response.text())
    .catch(handleError);
};
export const postBbAccountsConfiguration = async (data, clientId, token) => {
  const url = new URL(`${baseUrl}/Clients/${clientId}/BlueBird/BbAccount`);

  let options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options)
    .then((response) => response.text())
    .catch(handleError);
};
export const updateBbAccountsConfiguration = async (data, clientId, token) => {
  const url = new URL(`${baseUrl}/Clients/${clientId}/BlueBird/BbAccount`);

  let options = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options)
    .then((response) => response.text())
    .catch(handleError);
};
export const updateDBRReportsConfiguration = async (data, clientId, token) => {
  const url = new URL(`${baseUrl}/Clients/${clientId}/BlueBird/PbsDealer`);

  let options = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options)
    .then((response) => response.text())
    .catch(handleError);
};

export const deleteBbAccountsConfiguration = async (
  clientId,
  accountId,
  token
) => {
  const url = new URL(`${baseUrl}/Clients/${clientId}/BlueBird/BbAccount`);
  url.searchParams.append("accountId", accountId);
  let options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const deleteDBRReportsConfiguration = async (
  clientId,
  dealerId,
  token
) => {
  const url = new URL(`${baseUrl}/Clients/${clientId}/BlueBird/PbsDealer`);
  url.searchParams.append("dealerId", dealerId);
  let options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};
