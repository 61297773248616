import React, { useEffect, useState } from "react";
import { Fade, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

let openToastFn;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackbarWrapper = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const [severity, setSeverity] = useState();

  const openToast = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  useEffect(() => {
    openToastFn = openToast;
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      style={{ marginRight: "4rem" }}
      open={open}
      autoHideDuration={severity === "success" ? 3000 : 10000}
      onClose={handleClose}
      TransitionComponent={Fade}
    >
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  );
};

export function showSuccessMessage(message, severity) {
  openToastFn(message, severity || "success");
}

export default SnackbarWrapper;
