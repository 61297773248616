import React from "react";
import { AuthConsumer } from "./AuthProvider";
import { Backdrop, CircularProgress } from "@material-ui/core";

export const Callback = () => (
  <AuthConsumer>
    {({ signinRedirectCallback }) => {
      signinRedirectCallback();
      return (
        <Backdrop open={true}>
          <CircularProgress color="primary" size={60} />
        </Backdrop>
      );
    }}
  </AuthConsumer>
);
