import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const getDepositDetail = async (token, type, id) => {
  const url = new URL(`${baseUrl}/Deposit/${type}Deposit`);

  url.searchParams.append("id", id);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getDepositMethods = async (clientId, token) => {
  const url = new URL(`${baseUrl}/Deposit/DepositMethods`);

  url.searchParams.append("ClientId", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const deleteDeposit = async (token, id, data) => {
  const url = new URL(`${baseUrl}/Deposit/${id}`);

  let options = {
    method: "DELETE",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const getDepositData = async (
  clientId,
  token,
  id,
  type,
  depositType
) => {
  const url = new URL(`${baseUrl}/Deposit/${id}`);

  url.searchParams.append("clientId", clientId);
  url.searchParams.append("referenceType", type);
  url.searchParams.append("depositType", depositType);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getDepositSummary = async (clientId, token, id, type) => {
  const url = new URL(`${baseUrl}/Deposit/Summary`);

  url.searchParams.append("clientId", clientId);
  url.searchParams.append("referenceId", id);
  url.searchParams.append("referenceType", type);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const addManualDeposit = async (token, data) => {
  const url = new URL(`${baseUrl}/Deposit`);

  let options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response.json())
    .catch(handleError);
};

export const updateDepositData = async (token, id, data) => {
  const url = new URL(`${baseUrl}/Deposit/${id}`);

  let options = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const printDeposit = async (id, clientId, token, type, depositType) => {
  const url = new URL(`${baseUrl}/Deposit/${id}/Print`);

  url.searchParams.append("clientId", clientId);
  url.searchParams.append("depositType", depositType);
  url.searchParams.append("referenceType", type);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options)
    .then((response) => response.text())
    .catch(handleError);
};

export const checkDeposit = async (id, type, isDelete, token) => {
  const url = new URL(`${baseUrl}/Deposit/IsTransactionAvailable`);

  url.searchParams.append("referenceId", id);
  url.searchParams.append("referenceType", type);
  url.searchParams.append("isdeleted", isDelete);
  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options)
    .then((response) => response.text())
    .catch(handleError);
};
