export const SET_PERSONAL_INFORMATION = "SET_PERSONAL_INFORMATION";
export const SET_PERSONAL_SEARCH_QUERY = "SET_PERSONAL_SEARCH_QUERY";
export const SET_INSURANCE_INFORMATION = "SET_INSURANCE_INFORMATION";

export const SET_VEHICLE_SEARCH_QUERY = "SET_VEHICLE_SEARCH_QUERY";
export const SET_VEHICLES = "SET_VEHICLES";
export const SET_RATE_TYPES = "SET_RATE_TYPES";
export const SET_SUMMARY_INFO = "SET_SUMMARY_INFO";
export const SET_ATTRIBUTES = "SET_ATTRIBUTES";

export const SET_RENTAL = "SET_RENTAL";
export const SET_VEHICLE = "SET_VEHICLE";
export const SET_VEHICLE_TELEMETRY = "SET_VEHICLE_TELEMETRY";
export const SET_DRIVER_SEARCH_QUERY = "SET_DRIVER_SEARCH_QUERY";
export const SET_DRIVERS_MAIN = "SET_DRIVERS_MAIN";
export const SET_DRIVERS_SUB = "SET_DRIVERS_SUB";
export const DELETE_DRIVER = "DELETE_DRIVER";
export const SET_REFERRAL = "SET_REFERRAL";
export const SET_BILLING = "SET_BILLING";
export const SET_AIRPORT = "SET_AIRPORT";
export const SET_OTHER = "SET_OTHER";

export const SET_RATES_AND_TAXES = "SET_RATES_AND_TAXES";
export const GET_RATES = "GET_RATES";
export const SET_RATES = "SET_RATES";

// PACKAGE RATES
export const GET_PACKAGE_RATES_TYPES = "GET_PACKAGE_RATES_TYPES";
export const SET_PACKAGE_RATES_TYPES = "SET_PACKAGE_RATES_TYPES";



export const SET_PACKAGE = "SET_PACKAGE";


export const SET_TAXES = "SET_TAXES";
export const SET_MISCELLANEOUS_CHARGES = "SET_MISCELLANEOUS_CHARGES";
export const SET_ADJUSTMENTS = "SET_ADJUSTMENTS";

export const SET_NOTES = "SET_NOTES";
export const SET_EQUIPMENT = "SET_EQUIPMENT";
export const SET_EXPENSE = "SET_EXPENSE";
export const RESET_ADD_RESERVATION = "RESET_ADD_RESERVATION";

export const SET_PLANNER_RENTAL = "SET_PLANNER_RENTAL";

export const ENABLE_RATE_EDIT = "ENABLE_RATE_EDIT";

export const RESET_RATES_AND_SUMMARY = "RESET_RATES_AND_SUMMARY";

export const SET_OPTIMAL_RATE_TYPE = "SET_OPTIMAL_RATE_TYPE";

export const ADD_API_CALL_IN_PROGRESS = "ADD_API_CALL_IN_PROGRESS";


