import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const getModels = async (clientId, makeId, isOwnedOnly, token) => {
  const url = new URL(`${baseUrl}/VehicleModels/${makeId}`);
  url.searchParams.append("makeid", makeId);
  url.searchParams.append("clientid", clientId);

  if(isOwnedOnly){
    url.searchParams.append("isOwnedOnly", isOwnedOnly);
  }

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getAllModels = async (clientId, token) => {
  const url = new URL(`${baseUrl}/VehicleModels`);
  url.searchParams.append("clientid", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const addVehicleModel = async (token, data) => {
  const url = new URL(`${baseUrl}/VehicleModels`);

  let options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
  };

  return fetch(url, options).then(response => response).catch(handleError);
};

export const deleteVehicleModel = async ( token, id, clientId, userId) => {
  const url = new URL(`${baseUrl}/VehicleModels/${id}`);

  url.searchParams.append("clientId", clientId);
  url.searchParams.append("userId", userId);

  let options = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
  };
  return fetch(url, options).then(response => response).catch(handleError);
};

export const updateVehicleModel = async (token, id, data) => {
  const url = new URL(`${baseUrl}/VehicleModels/${id}`);

  let options = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
  };

  return fetch(url, options).then(response => response).catch(handleError);
};