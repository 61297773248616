import {
  SET_CUSTOMER_ID,
  SET_VEHICLE_ID,
  SET_RESERVATION_ID,
  SET_AGREEMENT_ID,
  SET_INQUIRY_ID,
  SET_USER_ID,
  SET_REPORT_ID,
  SET_TEMPLATE_ID,
  SET_GPS_ID,
  SET_Credit_Note_ID,
  CHANGE_TO_SINGLE_PAGE_VIEW_CUSTOMER,
  CHANGE_TO_SINGLE_PAGE_VIEW_VEHICLE,
  CHANGE_TO_SINGLE_PAGE_VIEW_AGREEMENT,
  CHANGE_TO_SINGLE_PAGE_VIEW_RESERVATION,
} from "../types";

const initialState = {
  customerId: 0,
  vehicleId: 0,
  reservationId: 0,
  agreementId: 0,
  inquiryId: 0,
  userId: 0,
  templateId: 0,
  creditNoteId: 0,
  isCustomerFormSinglePageView: true,
  isVehicleFormSinglePageView: true,
  isAgreementFormSinglePageView: true,
  isReservationFormSinglePageView: true,
  squareupCustomerId: 0
};

const lookupIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMER_ID:
      return {
        ...state,
        customerId: action.payload,
      };
    case SET_VEHICLE_ID:
      return {
        ...state,
        vehicleId: action.payload,
      };
    case SET_RESERVATION_ID:
      return {
        ...state,
        reservationId: action.payload,
      };
    case SET_AGREEMENT_ID:
      return {
        ...state,
        agreementId: action.payload,
      };
    case SET_INQUIRY_ID:
      return {
        ...state,
        inquiryId: action.payload,
      };
    case SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };
    case SET_REPORT_ID:
      return {
        ...state,
        reportId: action.payload,
      };
    case SET_TEMPLATE_ID:
      return {
        ...state,
        templateId: action.payload,
      };
    case SET_GPS_ID:
      return {
        ...state,
        gpsId: action.payload,
      };
    case SET_Credit_Note_ID:
      return {
        ...state,
        creditNoteId: action.payload,
      };
    case CHANGE_TO_SINGLE_PAGE_VIEW_CUSTOMER:
      return {
        ...state,
        isCustomerFormSinglePageView: action.payload,
      };
    case CHANGE_TO_SINGLE_PAGE_VIEW_VEHICLE:
      return {
        ...state,
        isVehicleFormSinglePageView: action.payload,
      };
    case CHANGE_TO_SINGLE_PAGE_VIEW_AGREEMENT:
      return {
        ...state,
        isAgreementFormSinglePageView: action.payload,
      };
    case CHANGE_TO_SINGLE_PAGE_VIEW_RESERVATION:
      return {
        ...state,
        isReservationFormSinglePageView: action.payload,
      };
    default:
      return state;
  }
};

export default lookupIdReducer;
