import React, {
  Fragment,
  useRef,
  useCallback,
  useState,
  useContext,
  useEffect,
} from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import clsx from "clsx";
import {
  AppBar,
  Toolbar,
  Avatar,
  Drawer,
  List,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  Tooltip,
  Box,
  withStyles,
  withWidth,
  Button,
  MenuList,
  MenuItem,
  Grow,
  Paper,
  Popper,
  ClickAwayListener,
} from "@material-ui/core";

import MailIcon from "@material-ui/icons/Mail";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DescriptionIcon from "@material-ui/icons/Description";
import MenuIcon from "@material-ui/icons/Menu";
import PieChartIcon from "@material-ui/icons/PieChart";
import ScheduleIcon from "@material-ui/icons/Schedule";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SettingsIcon from "@material-ui/icons/Settings";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import NavigationDrawer from "./NavigationDrawer";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { AuthContext } from "../auth/AuthProvider";
import Logo from "./Logo";
import { useTranslation } from "react-i18next";
import GlobalSearch from "./GlobalSearch";
import { usePermission, useFeature } from "../auth/permissions";
import * as api from "../api";
import { useLocation, useParams } from "react-router";
import AddUser from "./Admin/ApplicationConfig/Users/AddUser";
import moment from "moment";
import { showSuccessMessage } from "./FormUI/Toast";
import { updateUser } from "../api";
import { getLocations } from "../api";
import ResetPwPopUp from "./Admin/ApplicationConfig/ResetPassword/ResetPwPopUp";
import HelpIcon from "@material-ui/icons/Help";
import FaqModal from "./DetailsCommonComponents/FaqModal";
import LinkDisplay from "./LinkesComponent";
import { set } from "date-fns";
import ChatBotModal from "./DetailsCommonComponents/ChatBotModal";

const drawerWidth = 240;

const styles = (theme) => ({
  appBar: {
    marginLeft: theme.spacing(7) + 1,
    width: `calc(100% - ${theme.spacing(7) + 1}px)`,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(8) + 1,
    },
    boxShadow: theme.shadow,
    backgroundColor: theme.palette.common.white,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginLeft: 0,
    },
  },

  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,

  drawer: {
    zIndex: theme.zIndex.appBar + 1,
    width: drawerWidth,
    flexShrink: 0,
  },
  navlist: {
    paddingTop: 0,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(8) + 1,
    },
  },

  appBarToolbar: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  accountAvatar: {
    marginLeft: theme.spacing(-2),
  },
  smBordered: {
    [theme.breakpoints.down("xs")]: {
      borderRadius: "50% !important",
    },
  },
  menuLink: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  iconListItem: {
    width: "auto",
    borderRadius: theme.shape.borderRadius,
    paddingTop: 11,
    paddingBottom: 11,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textPrimary: {
    color: theme.palette.text.primary,
  },
  username: {
    paddingLeft: 0,
    paddingRight: theme.spacing(2),
  },
  small: {
    marginRight: theme.spacing(3),
  },
  accountButton: {
    paddingRight: theme.spacing(4),
  },
});

function NavBar(props) {
  const { classes, width } = props;

  // Will be use to make website more accessible by screen readers
  const links = useRef([]);
  const [WebinarLink, setWebinarLink] = useState([]);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Dashboard");
  const [open, setOpen] = React.useState(false);
  const auth = useContext(AuthContext);
  const [openAccount, setOpenAccount] = React.useState(false);
  const anchorRef = React.useRef(null);
  const isShowGPS = usePermission("SHOW_GPS_TAB");
  const isShowAgreement = usePermission("VIEW_AGREEMENT_TAB");

  const isBoat = useFeature("BOAT_RENTAL");

  const handleAccountToggle = () => {
    setOpenAccount((prevOpen) => !prevOpen);
  };

  const handleAccountClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenAccount(false);
  };

  const handleSwitchToNavotar = async () => {
    const profile = auth.getProfile();

    const link = await api.getNavotarLink(profile.clientId, auth.getToken());
    if (link) {
      window.location.href = link;
    }
  };

  const getmessagsLink = async () => {
    const links = await api.fetchLink(profile.clientId, auth.getToken());
    setWebinarLink(links.filter((link) => link.title !== "all testing"));
  };

  const handleAccountListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenAccount(false);
    }
  };

  const prevOpen = React.useRef(openAccount);

  React.useEffect(() => {
    getmessagsLink();

    if (prevOpen.current === true && openAccount === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = openAccount;
  }, [openAccount]);

  const handleDrawerOpenClose = () => {
    setOpen(!open);
  };

  const { t } = useTranslation();

  const openMobileDrawer = useCallback(() => {
    setIsMobileOpen(true);
  }, [setIsMobileOpen]);

  const closeMobileDrawer = useCallback(() => {
    setIsMobileOpen(false);
  }, [setIsMobileOpen]);

  const getInitials = (user) => {
    return user && user.firstName
      ? user.firstName.charAt(0).toUpperCase() +
          user.lastName.charAt(0).toUpperCase()
      : "";
  };

  const menuItems = [
    {
      link: "/home",
      name: "Dashboard",
      displayName: t("menu.dashboard"),
      onClick: closeMobileDrawer,
      icon: {
        desktop: (
          <DashboardIcon
            className={
              selectedTab === "Dashboard"
                ? classes.primary
                : classes.textPrimary
            }
            fontSize="small"
          />
        ),
        mobile: <DashboardIcon className="text-white" />,
      },
    },
  ];

  if (usePermission("RESERVATION_VIEW_DAILY_VIEW")) {
    menuItems.push({
      link: "/dailyplanner",
      name: "Daily Planner",
      displayName: t("menu.dailyplanner"),
      onClick: closeMobileDrawer,
      icon: {
        desktop: (
          <ScheduleIcon
            className={
              selectedTab === "Daily Planner"
                ? classes.primary
                : classes.textPrimary
            }
            fontSize="small"
          />
        ),
        mobile: <ScheduleIcon className="text-white" />,
      },
    });
  }

  if (usePermission("VIEW_CUSTOMER_TAB")) {
    menuItems.push({
      link: "/customers",
      name: "Customers",
      displayName: t("menu.customers"),
      onClick: closeMobileDrawer,
      icon: {
        desktop: (
          <PeopleIcon
            className={
              selectedTab === "Customers"
                ? classes.primary
                : classes.textPrimary
            }
            fontSize="small"
          />
        ),
        mobile: <PeopleIcon className="text-white" />,
      },
    });
  }

  if (usePermission("VIEW_VEHICLE_TAB")) {
    menuItems.push({
      link: "/vehicles",
      name: "Vehicles",
      displayName: t("menu.vehicles", { context: isBoat ? "boat" : "vehicle" }),
      onClick: closeMobileDrawer,
      icon: {
        desktop: (
          <DriveEtaIcon
            className={
              selectedTab === "Vehicles" ? classes.primary : classes.textPrimary
            }
            fontSize="small"
          />
        ),
        mobile: <DriveEtaIcon className="text-white" />,
      },
    });
  }

  if (usePermission("VIEW_RESERVATION_TAB")) {
    menuItems.push({
      link: "/reservations",
      name: "Reservations",
      displayName: t("menu.reservations"),
      onClick: closeMobileDrawer,
      icon: {
        desktop: (
          <DateRangeIcon
            className={
              selectedTab === "Reservations"
                ? classes.primary
                : classes.textPrimary
            }
            fontSize="small"
          />
        ),
        mobile: <DateRangeIcon className="text-white" />,
      },
    });
  }

  if (usePermission("VIEW_AGREEMENT_TAB")) {
    menuItems.push({
      link: "/agreements",
      name: "Agreements",
      displayName: t("menu.agreements"),
      onClick: closeMobileDrawer,
      icon: {
        desktop: (
          <DescriptionIcon
            className={
              selectedTab === "Agreements"
                ? classes.primary
                : classes.textPrimary
            }
            fontSize="small"
          />
        ),
        mobile: <DescriptionIcon className="text-white" />,
      },
    });
  }

  if (useFeature("VIEW_CLAIM_TAB")) {
    menuItems.push({
      link: "/claims",
      name: "Claims",
      displayName: t("menu.claims"),
      onClick: closeMobileDrawer,
      icon: {
        desktop: (
          <AssignmentIcon
            className={
              selectedTab === "Claims" ? classes.primary : classes.textPrimary
            }
            fontSize="small"
          />
        ),
        mobile: <AssignmentIcon className="text-white" />,
      },
    });
  }

  if (usePermission("VIEW_REPORT_TAB")) {
    menuItems.push({
      link: "/reports",
      name: "Reports",
      displayName: t("menu.reports"),
      onClick: closeMobileDrawer,
      icon: {
        desktop: (
          <PieChartIcon
            className={
              selectedTab === "Reports" ? classes.primary : classes.textPrimary
            }
            fontSize="small"
          />
        ),
        mobile: <PieChartIcon className="text-white" />,
      },
    });
  }

  if (useFeature("GPS") && isShowGPS) {
    menuItems.push({
      link: "/gpstracker",
      name: "Gps Tracker",
      displayName: t("menu.gpstracker"),
      onClick: closeMobileDrawer,
      icon: {
        desktop: (
          <GpsFixedIcon
            className={
              selectedTab === "Gps Tracker"
                ? classes.primary
                : classes.textPrimary
            }
            fontSize="small"
          />
        ),
        mobile: <GpsFixedIcon className="text-white" />,
      },
    });
  }

  if (useFeature("WIALON") && isShowGPS) {
    menuItems.push({
      link: "/tracking",
      name: "Tracking",
      displayName: t("menu.gpstracker"),
      onClick: closeMobileDrawer,
      icon: {
        desktop: (
          <GpsFixedIcon
            className={
              selectedTab === "Tracking" ? classes.primary : classes.textPrimary
            }
            fontSize="small"
          />
        ),
        mobile: <GpsFixedIcon className="text-white" />,
      },
    });
  }

  if (useFeature("INVOICE_CREDIT_NOTE") && isShowAgreement) {
    menuItems.push({
      link: "/InvoiceCreditNote",
      name: "Invoice",
      displayName: t("menu.invoice"),
      onClick: closeMobileDrawer,
      icon: {
        desktop: (
          <ReceiptIcon
            className={
              selectedTab === "Invoice" ? classes.primary : classes.textPrimary
            }
            fontSize="small"
          />
        ),
        mobile: <ReceiptIcon className="text-white" />,
      },
    });
  }

  if (useFeature("DBR_REPORT")) {
    menuItems.push({
      link: "/dailybusinessreport",
      name: "DailyBusinessReport",
      displayName: t("menu.dailybusinessreport"),
      onClick: closeMobileDrawer,
      icon: {
        desktop: (
          <ReceiptIcon
            className={
              selectedTab === "DailyBusinessReport"
                ? classes.primary
                : classes.textPrimary
            }
            fontSize="small"
          />
        ),
        mobile: <ReceiptIcon className="text-white" />,
      },
    });
  }

  const location = useLocation();
  const url = location.pathname;

  useEffect(() => {
    getmessagsLink();
    if (url) {
      let id = url.split("/")[url.split("/")?.length - 1 || 0] || "";

      let idA = url.split("/")[url.split("/")?.length - 2 || 0] || "";

      if (url === "/home") {
        setSelectedTab("Dashboard");
      }

      if (url === "/dailyplanner") {
        setSelectedTab("Daily Planner");
      }

      if (url === "/claims") {
        setSelectedTab("Claims");
      }

      if (url === "/reports") {
        setSelectedTab("Reports");
      }

      if (url === "/gpstracker") {
        setSelectedTab("Gps Tracker");
      }

      if (
        [
          `/AgreementDetails/${id}`,
          `/agreements/checkin-agreement/${idA}`,
          `/agreements/checkin-agreement/${idA}/${id}`,
          `/agreements/add-agreement/${id}`,
          `/agreements/edit-agreement/${idA}/${id}`,
          `/agreements`,
        ].includes(url)
      ) {
        let element = menuItems?.find((e) => e.name === "Agreements");
        let index = menuItems?.indexOf(element);
        // links.current[index]?.click();
        setSelectedTab("Agreements");
      }
      if (
        [
          `/ReservationDetails/${id}`,
          `/reservations/edit-reservation/${idA}/${id}`,
          `/reservations/add-reservation/${id}`,
          `/reservations`,
        ].includes(url)
      ) {
        let element = menuItems?.find((e) => e.name === "Reservations");
        let index = menuItems?.indexOf(element);
        // links.current[index]?.click();
        setSelectedTab("Reservations");
      }
      if (
        [
          `/CustomerDetails/${id}`,
          `/update-customer/${id}`,
          `/add-customer`,
          `/customers`,
        ].includes(url)
      ) {
        let element = menuItems?.find((e) => e.name === "Customers");
        let index = menuItems?.indexOf(element);
        // links.current[index]?.click();
        setSelectedTab("Customers");
      }
      if (
        [
          `/VehicleDetails/${id}`,
          `/update-vehicle/${id}`,
          `/add-vehicle`,
          `/vehicles`,
        ].includes(url)
      ) {
        let element = menuItems?.find((e) => e.name === "Vehicles");
        let index = menuItems?.indexOf(element);
        // links.current[index]?.click();
        setSelectedTab("Vehicles");
      }
    }
  }, [url]);

  const profile = auth.getProfile();

  const [openUser, setOpenUser] = useState(false);

  const handleOpenUser = () => {
    setOpenUser(true);
  };

  const handleCloseUser = () => {
    setOpenUser(false);
  };

  const [loading, setLoading] = useState(false);

  const saveUserDetails = async (values) => {
    setLoading(true);

    const selectedLocations = values?.selectedLocations || [];

    let list = [];
    let count = 0;

    for (let index = 0; index < selectedLocations.length; index++) {
      const location = selectedLocations[index];

      let record = locations?.find((obj) => obj.locationId === location);

      if (record != null) {
        let object = {
          locationId: record.locationId,
          locationName: record.locationName,
          isSelected: true,
        };

        list[count] = object;

        count++;
      }
    }

    if (profile.userId) {
      const userData = {
        clientId: Number(profile.clientId) || 0,
        createdBy: Number(profile.userId) || 0,
        createdDate: moment().format("yyyy-MM-DDTHH:mm") || "",
        userName: values?.userName || "",
        firstName: values?.firstName || "",
        lastName: values?.lastName || "",
        phone: values?.phoneNo || "",
        email: values?.email || "",
        scanAccessKey: values?.scanAccKey || "",
        userRoleID: values?.userRole.userRoleID || 0,
        language: values?.language.key || "",
        locationList: list,
        isActive: Boolean(values?.active) || false,
        lockOut: Boolean(values?.lock) || false,
        isReservationEmail: Boolean(values?.isReservation) || false,
      };
      await updateUser(profile.userId, userData, auth.getToken())
        .then((res) => {
          if (res.status === 200) {
            showSuccessMessage(t("messages.updateSuccess"));
            setLoading(false);
          } else {
            showSuccessMessage(t("messages.updateFailed"), "error");
            setLoading(false);
          }
        })
        .catch((e) => {
          showSuccessMessage(t("messages.updateFailed"), "error");
          setLoading(false);
        });
    } else {
      showSuccessMessage(t("messages.updateFailed"), "error");
      setLoading(false);
    }
  };

  const [locations, setLocations] = useState(null);

  useEffect(() => {
    getmessagsLink();
    getLocationList();
  }, [setLocations]);

  const getLocationList = async () => {
    await getLocations(profile.clientId, profile.userId, auth.getToken(), true)
      .then((locations) => {
        setLocations(locations);
      })
      .catch((error) => setLocations(null));
  };

  const [openReset, setOpenReset] = useState(false);

  const handleOpenReset = () => {
    setOpenReset(true);
  };

  const handleCloseReset = () => {
    setOpenReset(false);
  };

  const [openFaqModal, setOpenFaqModal] = useState(false);
  const [openChat, setOpenChat] = useState(false);

  return (
    <Fragment>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.appBarToolbar}>
          <Box display="flex" alignItems="center">
            <Hidden smUp>
              <Box mr={1}>
                <IconButton
                  aria-label="Open Navigation"
                  onClick={openMobileDrawer}
                  color="primary"
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            </Hidden>
          </Box>
          <Box display="flex" justifyContent="flex-start" width="50%">
            <Hidden smDown>
              <GlobalSearch />
            </Hidden>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              width="100%"
              paddingLeft={2}
            >
              <LinkDisplay links={WebinarLink} />
            </Box>
          </Box>

          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            width="50%"
          >
            {/* <Button
              variant="contained"
              color="primary"
              className={classes.small}
              onClick={handleSwitchToNavotar}
            >
              {`${t("buttons.switchToNavotar")}`}
            </Button> */}
            {/* <Avatar variant="square" className={classes.small}>
              <NotificationsIcon />
            </Avatar>
            <Avatar variant="square" className={classes.small}>
              <MailIcon />
            </Avatar> */}
            {usePermission("VIEW_ADMIN_TAB") && (
              <Avatar
                variant="square"
                className={classes.small}
                component={Link}
                to="/admin"
              >
                <SettingsIcon />
              </Avatar>
            )}
            {true && (
              <div
                className={classes.small}
                style={{ width: "40px", height: "40px", cursor: "pointer" }}
                onClick={(event) => {
                  setOpenFaqModal(true);
                }}
              >
                <Avatar variant="square">
                  <HelpIcon />
                </Avatar>
              </div>
            )}
            <Hidden xsDown>
              <Button
                variant="contained"
                color="secondary"
                endIcon={<ExpandMoreIcon />}
                className={classes.accountButton}
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleAccountToggle}
              >
                {`${t("buttons.hello")}, ${props.user.firstName}`}
              </Button>
            </Hidden>
            <Avatar
              src={`${process.env.PUBLIC_URL}/images/profilePicture.jpg`}
              className={classNames(classes.accountAvatar)}
              onClick={handleAccountToggle}
            >
              {getInitials(props.user)}
            </Avatar>
            <Popper
              open={openAccount}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleAccountClose}>
                      <MenuList
                        autoFocusItem={openAccount}
                        id="menu-list-grow"
                        onKeyDown={handleAccountListKeyDown}
                      >
                        <MenuItem
                          onClick={(e) => {
                            handleOpenUser();
                            handleAccountClose(e);
                          }}
                        >
                          {t("buttons.profile")}
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            handleOpenReset();
                            handleAccountClose(e);
                          }}
                        >
                          {t("buttons.changePassword")}
                        </MenuItem>
                        {/* <MenuItem onClick={handleSwitchToNavotar}>
                          {`${t("buttons.switchToNavotar")}`}
                        </MenuItem> */}
                        <MenuItem component={Link} to="/logout">
                          {t("buttons.logout")}
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        </Toolbar>
      </AppBar>
      <Hidden xsDown>
        <Drawer //  both drawers can be combined into one for performance
          variant="permanent"
          open={true}
          anchor="left"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <Tooltip title={open ? "Collapse Menu" : "Expand Menu"}>
              <Box pt={1} pl={0.2}>
                <Logo
                  onClick={handleDrawerOpenClose}
                  style={{ cursor: "pointer" }}
                />
              </Box>
            </Tooltip>
          </div>
          <List className={classes.navlist}>
            {menuItems.map((element, index) => (
              <Link
                to={element.link}
                className={classes.menuLink}
                onClick={element.onClick}
                key={index}
                ref={(node) => {
                  links.current[index] = node;
                }}
              >
                <Tooltip
                  title={element.displayName}
                  placement="right"
                  key={element.name}
                >
                  <ListItem
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    selected={selectedTab === element.name}
                    button
                    divider={index !== menuItems.length - 1}
                    onClick={() => {
                      links.current[index].click();
                      setSelectedTab(element.name);
                    }}
                    aria-label={`Go to ${element.name}`}
                  >
                    <ListItemIcon>{element.icon.desktop}</ListItemIcon>
                    <ListItemText primary={element.displayName} />
                  </ListItem>
                </Tooltip>
              </Link>
            ))}
          </List>
          {/* <Box
            position="absolute"
            bottom="0px"
            style={{ width: "100%" }}
            textAlign="center"
          >
            <Tooltip title={t("menu.chat")} placement="right">
              <IconButton
                color="primary"
                aria-label="chat"
                component="span"
                onClick={() => {
                  closeMobileDrawer();
                  setOpenChat(true);
                }}
              >
                <ChatBubbleIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box> */}
        </Drawer>
      </Hidden>
      <NavigationDrawer
        menuItems={menuItems.map((element) => ({
          link: element.link,
          name: element.name,
          displayName: element.displayName,
          icon: element.icon.mobile,
          onClick: element.onClick,
        }))}
        anchor="left"
        open={isMobileOpen}
        selectedItem={selectedTab}
        onClose={closeMobileDrawer}
      />

      {openUser && (
        <AddUser
          open={openUser}
          handleClose={handleCloseUser}
          save={saveUserDetails}
          head={`${t("headings.edit")} ${t("headings.user")}`}
          idValue={profile.userId}
          loading={loading}
        />
      )}

      {openReset && (
        <ResetPwPopUp
          open={openReset}
          handleClose={handleCloseReset}
          head={`${t("headings.changePassword")}`}
        />
      )}

      {openFaqModal && (
        <FaqModal
          open={openFaqModal}
          handleClose={() => setOpenFaqModal(false)}
        />
      )}

      {openChat && (
        <ChatBotModal open={openChat} handleClose={() => setOpenChat(false)} />
      )}
    </Fragment>
  );
}

NavBar.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.object),
  selectedTab: PropTypes.string,
  width: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withWidth()(withStyles(styles, { withTheme: true })(NavBar));
