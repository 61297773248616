import * as types from "../types";
import initialState from "../initialState";

const lookupListsReducer = (
  state = initialState.lookupLists,
  { type, lists }
) => {
  switch (type) {
    case types.LOAD_LOOKUP_LISTS_SUCCESS:
      return {
        ...state,
        ...lists,
      };

    case types.LOAD_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: lists,
      };
    case types.LOAD_VEHICLE_TYPES_SUCCESS:
      return {
        ...state,
        vehicleTypes: lists,
      };
    case types.LOAD_INSURANCE_COMPANIES_SUCCESS:
      return {
        ...state,
        insuranceCompanies: lists,
      };
    case types.LOAD_LEASING_COMPANIES_SUCCESS:
      return {
        ...state,
        leasingCompanies: lists,
      };
    default:
      return state;
  }
};

export default lookupListsReducer;
