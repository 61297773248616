import { SET_DAMAGE_DATA } from "../types/damagePrintTypes";

const initialState = {
  damagePrintData: {},
};

const damagePrintReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DAMAGE_DATA:
      return {
        ...state,
        damagePrintData: action.payload,
      };

    default:
      return state;
  }
};

export default damagePrintReducer;
