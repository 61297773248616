import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const getAgreements = async (filtersValues, token) => {
  const url = new URL(`${baseUrl}/Agreements`);

  for (var key in filtersValues) {
    if (
      key !== null &&
      filtersValues[key] !== undefined &&
      filtersValues.hasOwnProperty(key) &&
      filtersValues[key] !== "FullName" &&
      filtersValues[key] !== "" &&
      filtersValues[key] !== "0" &&
      filtersValues[key] !== null &&
      filtersValues[key] !== 0
    ) {
      url.searchParams.append(key, filtersValues[key]);
    }
  }

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getAgreementTypes = async (clientId, token) => {
  const url = new URL(`${baseUrl}/Agreements/Types`);

  url.searchParams.append("clientid", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getAgreementStatuses = async (clientId, token) => {
  const url = new URL(`${baseUrl}/Agreements/Statuses`);

  url.searchParams.append("clientid", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getAgreementNote = async (token, id) => {
  const url = new URL(`${baseUrl}/Agreements/${id}/Notes`);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getAgreement = async (token, clientId, id) => {
  const url = new URL(`${baseUrl}/Agreements/${id}`);

  url.searchParams.append("clientid", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const saveAgreement = async (values, token) => {
  const response = await fetch(`${baseUrl}/Agreements`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(values),
  })
    .then(handleResponse)
    .catch(handleError);

  return { ...response, status: true };
};

export const updateAgreement = async (id, values, token) => {
  const url = new URL(`${baseUrl}/Agreements/${id}`);

  let options = {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(values),
  };

  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const checkInAgreement = async (id, values, token) => {
  const response = await fetch(`${baseUrl}/Agreements/${id}/Checkin`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(values),
  });

  return { response };
};

export const checkInAgreementData = async (id, values, token) => {
  const url = new URL(`${baseUrl}/Agreements/${id}/Checkin`);

  let options = {
    method: "POST",
    body: JSON.stringify(values),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response.json())
    .catch(handleError);
};

export const deleteAgreement = async (token, id, data) => {
  const url = new URL(`${baseUrl}/Agreements/${id}`);

  let options = {
    method: "DELETE",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const updateOther = async (token, id, data) => {
  const url = new URL(`${baseUrl}/Agreements/${id}`);

  let options = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const voidAgreement = async (token, id, data) => {
  const url = new URL(`${baseUrl}/Agreements/${id}/Void`);

  let options = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const generateAgreementNo = async (token, clientId, agreementType) => {
  const url = new URL(`${baseUrl}/Agreements/GenerateAgreementNo`);

  url.searchParams.append("clientid", clientId);
  url.searchParams.append("agreementType", agreementType);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const CreateLoanerAgreement = async (token, clientId, code) => {
  const url = new URL(`${baseUrl}/Agreements/CreateLoanerAgreement`);

  url.searchParams.append("clientid", clientId);
  url.searchParams.append("pacckageRateCode", code);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getAgreementLoaner = async (token, clientId, id) => {
  const url = new URL(`${baseUrl}/Agreements/${id}/GetLoanerAgreement`);

  url.searchParams.append("clientid", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const putAgreementStatus = async (id, data, token) => {
  const url = new URL(`${baseUrl}/Agreements/${id}/Status`);

  let options = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response.text())
    .catch(handleError);
};

export const getAgreementsExcel = async (filtersValues, token) => {
  const url = new URL(`${baseUrl}/Agreements/Excel`);

  for (var key in filtersValues) {
    if (
      key !== null &&
      filtersValues[key] !== undefined &&
      filtersValues.hasOwnProperty(key) &&
      filtersValues[key] !== "FullName" &&
      filtersValues[key] !== "" &&
      filtersValues[key] !== "0" &&
      filtersValues[key] !== null &&
      filtersValues[key] !== 0
    ) {
      url.searchParams.append(key, filtersValues[key]);
    }
  }

  let options = {
    method: "POST",
    body: JSON.stringify({}),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", `Agreement.csv`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    })
    .catch(handleError);
};

export const getEquipmentTypes = async (token) => {
  const url = new URL(`${baseUrl}/Agreements/EquipmentTypes`);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getEquipmentCondition = async (token) => {
  const url = new URL(`${baseUrl}/Agreements/EquipmentCondition`);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getMaintenanceAlert = async (
  returenedDate,
  odometerIn,
  vehicleId,
  clientId,
  token
) => {
  const url = new URL(`${baseUrl}/Agreements/MaintenanceAlert`);

  url.searchParams.append("clientid", clientId);
  url.searchParams.append("vehicleId", vehicleId);
  url.searchParams.append("odometerIn", odometerIn);
  url.searchParams.append("returenedDate", returenedDate);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};
