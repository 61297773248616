import React, { lazy } from "react";
import { Switch, Route } from "react-router-dom";
import { PrivateRoute } from "./auth/PrivateRoute";
import { Callback } from "./auth/Callback";
import { Logout } from "./auth/Logout";
import { LogoutCallback } from "./auth/LogoutCallback";
import { SilentRenew } from "./auth/SilentRenew";

const PasswordReset = lazy(() => import("./auth/PasswordReset"));

const Customers = lazy(() => import("./pages/Customers"));
const CustomerDetails = lazy(() => import("./pages/CustomerDetails"));
const CustomerForm = lazy(() => import("./pages/AddCustomer"));
const Vehicles = lazy(() => import("./pages/Vehicles"));
const AddVehicle = lazy(() => import("./pages/AddVehicle"));
const VehicleDetails = lazy(() => import("./pages/VehicleDetails"));
const Reservations = lazy(() => import("./pages/Reservations"));
const AddReservation = lazy(() => import("./pages/AddReservation"));
const AddAgreement = lazy(() => import("./pages/AddAgreement"));
const Agreements = lazy(() => import("./pages/Agreements"));
const Admin = lazy(() => import("./pages/Admin"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Reports = lazy(() => import("./pages/Reports"));
const Todos = lazy(() => import("./pages/ToDos"));
const DailyPlanner = lazy(() => import("./pages/DailyPlanner"));
const ReservationDetails = lazy(() => import("./pages/ReservationDetails"));
const AgreementDetails = lazy(() => import("./pages/AgreementDetails"));
const Inquiry = lazy(() => import("./pages/Inquiry"));
const EditReservation = lazy(() => import("./pages/EditReservation"));
const EditAgreement = lazy(() => import("./pages/EditAgreement"));
const Claims = lazy(() => import("./pages/Claim"));
const CheckInAgreement = lazy(() => import("./pages/CheckInAgreement"));
const InquiryDetails = lazy(() => import("./pages/InquiryDetails"));
const ReportSelection = lazy(() => import("./pages/ReportSelection"));
const ServiceAlerts = lazy(() => import("./pages/ServiceAlerts"));
const AddLoanerAgreement = lazy(() => import("./pages/AddLoanerAgreement"));
const TrafficTickets = lazy(() => import("./pages/TrafficTicket"));
const CheckLoaner = lazy(() => import("./pages/CheckInLoaner"));
const GpsTracker = lazy(() => import("./pages/Gps"));
const WialonGps = lazy(() => import("./pages/WialonGps"));
const CreditNoteDetails = lazy(() => import("./pages/CreditNoteDetails"));
const CreditNoteView = lazy(() => import("./pages/CreditNoteView"));
const InvoiceCreditNote = lazy(() => import("./pages/InvoiceCreditNote"));
const PrintPage = lazy(() =>
	import("./components/Details/DamageTab/PrintPage")
);
const SupportModel = lazy(() => import("./pages/SupportPackageBanner"));
const DailyBusinessReport = lazy(() => import("./pages/DailyBusinessReport"));

const Router = () => (
	<Switch>
		<Route exact={true} path="/signin-oidc" component={Callback} />
		<Route exact={true} path="/logout" component={Logout} />
		<Route exact={true} path="/logout/callback" component={LogoutCallback} />
		<Route exact={true} path="/silentrenew" component={SilentRenew} />
		<Route path="/passwordreset" component={PasswordReset} />

		<PrivateRoute path="/customers" component={Customers} />
		<PrivateRoute path="/add-customer" component={CustomerForm} />
		<PrivateRoute path="/update-customer/:id" component={CustomerForm} />
		<PrivateRoute path="/vehicles" component={Vehicles} />
		<PrivateRoute path="/add-vehicle" component={AddVehicle} />
		<PrivateRoute path="/update-vehicle/:id" component={AddVehicle} />
		<PrivateRoute exact path="/reservations" component={Reservations} />
		<PrivateRoute
			path="/reservations/add-reservation"
			component={AddReservation}
		/>
		<PrivateRoute
			path="/reservations/edit-reservation/:id"
			component={EditReservation}
		/>
		<PrivateRoute path="/agreements/add-agreement" component={AddAgreement} />
		<PrivateRoute
			path="/agreements/continue-to-agreement"
			component={AddAgreement}
		/>
		<PrivateRoute
			path="/agreements/edit-agreement/:id"
			component={EditAgreement}
		/>
		<PrivateRoute
			path="/agreements/checkin-agreement/:id"
			component={CheckInAgreement}
		/>
		<PrivateRoute path="/Add-LoanerAgreements" component={AddLoanerAgreement} />
		<PrivateRoute
			path="/Update-LoanerAgreements"
			component={AddLoanerAgreement}
		/>
		<PrivateRoute path="/Check-In-LoanerAgreements" component={CheckLoaner} />
		<PrivateRoute
			path="/ReservationDetails/:id"
			component={ReservationDetails}
		/>
		<PrivateRoute exact path="/agreements" component={Agreements} />
		<PrivateRoute exact path="/claims" component={Claims} />
		<PrivateRoute path="/AgreementDetails/:id" component={AgreementDetails} />
		<PrivateRoute path="/VehicleDetails/:id" component={VehicleDetails} />
		<PrivateRoute path="/CustomerDetails/:id" component={CustomerDetails} />
		<PrivateRoute path="/admin" component={Admin} />
		<PrivateRoute path="/report" component={Reports} />
		<PrivateRoute path="/reports" component={ReportSelection} />
		<PrivateRoute path="/home" component={Dashboard} />
		<PrivateRoute path="/todos" component={Todos} />
		<PrivateRoute path="/dailyplanner" component={DailyPlanner} />
		<PrivateRoute path="/InquiryDetails" component={InquiryDetails} />
		<PrivateRoute exact path="/Inquiry" component={Inquiry} />
		<PrivateRoute exact path="/servicealerts" component={ServiceAlerts} />
		<PrivateRoute exact path="/TrafficTickets" component={TrafficTickets} />
		<PrivateRoute exact path="/gpstracker" component={GpsTracker} />
		<PrivateRoute exact path="/tracking" component={WialonGps} />
		<PrivateRoute
			path="/AgreementDetail/:id/CreditNoteDetails"
			component={CreditNoteDetails}
		/>
		<PrivateRoute path="/DamageReport/:id" component={PrintPage} />
		<PrivateRoute
			path="/AgreementDetail/:id/CreditNoteDetail/CreditNoteView/:creditNoteId"
			component={CreditNoteView}
		/>
		<PrivateRoute path="/InvoiceCreditNote" component={InvoiceCreditNote} />
		<PrivateRoute path="/rentall-support" component={SupportModel} />
		<PrivateRoute path="/dailybusinessreport" component={DailyBusinessReport} />
		<PrivateRoute path="/" component={Dashboard} />
	</Switch>
);

export default Router;
