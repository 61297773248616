import { baseUrl, handleResponse, handleError } from "./apiUtils";

import moment from "moment";

export const getReservations = async (filtersValues, token) => {
  const url = new URL(`${baseUrl}/Reservations`);

  const { Statuses } = filtersValues;

  let clientDate = moment().format("YYYY-MM-DD");
  url.searchParams.append("clientDate", clientDate);

  for (var key in filtersValues) {
    if (
      key !== null &&
      filtersValues.hasOwnProperty(key) &&
      filtersValues[key] !== "" &&
      filtersValues[key] !== "0" &&
      filtersValues[key] !== null &&
      filtersValues[key] !== 0 &&
      key !== "Statuses"
    ) {
      url.searchParams.append(key, filtersValues[key]);
    }
  }

  if (Statuses && Statuses?.length > 0 && typeof Statuses === "object") {
    Statuses.map((item) => url.searchParams.append("Statuses", item));
  } else {
    Statuses > 0 && url.searchParams.append("Statuses", Statuses);
  }

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getReservationTypes = async (clientId, token) => {
  const url = new URL(`${baseUrl}/Reservations/Types`);

  url.searchParams.append("clientid", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getReservationStatuses = async (token) => {
  const url = new URL(`${baseUrl}/Reservations/Statuses`);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const saveReservation = async (values, token) => {
  const response = await fetch(`${baseUrl}/Reservations`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(values),
  })
    .then(handleResponse)
    .catch(handleError);

  return { ...response, status: true };
};

export const getReservationById = async (id, clientId, token) => {
  const url = new URL(`${baseUrl}/Reservations/${id}`);

  url.searchParams.append("clientid", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const updateReservation = async (id, values, token) => {
  const url = new URL(`${baseUrl}/Reservations/${id}`);

  let options = {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(values),
  };

  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const cancelReservation = async (token, id, filtersValues, data) => {
  const url = new URL(`${baseUrl}/Reservations/${id}/Cancel`);

  for (var key in filtersValues) {
    if (filtersValues.hasOwnProperty(key) && filtersValues[key] !== "") {
      url.searchParams.append(key, filtersValues[key]);
    }
  }

  let options = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const confirmReservation = async (clientId, id, token) => {
  const url = new URL(`${baseUrl}/Reservations/${id}/Confirm`);

  url.searchParams.append("clientId", clientId);

  let options = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const getCancelCheck = async (
  clientId,
  userId,
  cancelledDate,
  id,
  token
) => {
  const url = new URL(`${baseUrl}/Reservations/${id}/CancelCheck`);

  url.searchParams.append("clientId", clientId);

  url.searchParams.append("userId", userId);

  url.searchParams.append("cancelledDate", cancelledDate);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const deleteReservation = async (
  clientId,
  userId,
  reservationId,
  token
) => {
  const url = new URL(`${baseUrl}/Reservations/${reservationId}`);

  url.searchParams.append("clientId", clientId);
  url.searchParams.append("userId", userId);

  let options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const changeStatusNew = async (clientId, id, token) => {
  const url = new URL(`${baseUrl}/Reservations/${id}/New`);

  url.searchParams.append("clientId", clientId);

  let options = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const getreservationExcel = async (filtersValues, token) => {
  const url = new URL(`${baseUrl}/Reservations/Excel`);

  // for (var key in filtersValues) {
  //   if (
  //     key !== null &&
  //     filtersValues[key] !== undefined &&
  //     filtersValues.hasOwnProperty(key) &&
  //     filtersValues[key] !== "FullName" &&
  //     filtersValues[key] !== "" &&
  //     filtersValues[key] !== "0" &&
  //     filtersValues[key] !== null &&
  //     filtersValues[key] !== 0
  //   ) {
  //     url.searchParams.append(key, filtersValues[key]);
  //   }
  // }

  const { Statuses } = filtersValues;

  let clientDate = moment().format("YYYY-MM-DD");
  url.searchParams.append("clientDate", clientDate);

  for (var key in filtersValues) {
    if (
      key !== null &&
      filtersValues.hasOwnProperty(key) &&
      filtersValues[key] !== "" &&
      filtersValues[key] !== "0" &&
      filtersValues[key] !== null &&
      filtersValues[key] !== 0 &&
      key !== "Statuses"
    ) {
      url.searchParams.append(key, filtersValues[key]);
    }
  }

  if (Statuses && Statuses?.length > 0 && typeof Statuses === "object") {
    Statuses.map((item) => url.searchParams.append("Statuses", item));
  } else {
    Statuses > 0 && url.searchParams.append("Statuses", Statuses);
  }

  let options = {
    method: "POST",
    body: JSON.stringify({}),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
  .then(response => response.blob())
  .then((blob) => {
    // Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;

    link.setAttribute("download", `Reservation.csv`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  })
  .catch(handleError);
};
