import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const getWidgets = async ({ clientId, userId }, token) => {
  const url = new URL(`${baseUrl}/dashboard`);

  url.searchParams.append("clientid", clientId);
  url.searchParams.append("userId", userId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const updateWidget = async (widget, token) => {
  const response = await fetch(`${baseUrl}/dashboard`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(widget),
  })
    .then(handleResponse)
    .catch(handleError);

  return { ...response, status: true };
};

export const checkPasswordExpiry = async (data, token) => {
  const url = new URL(`${baseUrl}/Users/CheckPasswordGap`);

  let options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options)
    .then((response) => response.json())
    .catch(handleError);
};
