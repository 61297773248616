import {
  SET_RESERVATION_ID,
  SET_CUSTOMER_ID,
  SET_RESERVATION_INFO_BY_ID,
  RESET_EDIT_RESERVATION,
} from '../types/editReservationTypes';

const initialState = {
  id: '',
  customerId: '',
  data: {},
};

const editReservationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RESERVATION_ID:
      return {
        ...state,
        id: action.payload,
      };
    case SET_CUSTOMER_ID:
      return {
        ...state,
        customerId: action.payload,
      };
    case SET_RESERVATION_INFO_BY_ID:
      return {
        ...state,
        data: action.payload,
      };
    case RESET_EDIT_RESERVATION:
      return {
        id: '',
        customerId: '',
        data: {},
      };
    default:
      return state;
  }
};

export default editReservationReducer;
