import { baseUrl, handleResponse, handleError } from "./apiUtils";

import moment from "moment";

export const getStatistics = async (
  { clientId, userId, locationId },
  token
) => {
  const url = new URL(`${baseUrl}/Statistics`);

  let clientDate = moment().format("YYYY-MM-DD");
  url.searchParams.append("clientid", clientId);
  url.searchParams.append("userId", userId);
  url.searchParams.append("clientDate", clientDate);

  if (locationId && locationId.length > 1) {
    locationId.map((item) => url.searchParams.append("Multiplelocation", item));
  } else if (locationId && locationId.length === 1) {
    url.searchParams.append("locationId", locationId[0]);
  } else {
    url.searchParams.append("locationId", 0);
  }

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};


export const getPaymentDelays = async (
  { clientId, agreementTypeId },
  token
) => {
  const url = new URL(`${baseUrl}/Statistics/PaymentDelays`);

  let clientDate = moment().format("YYYY-MM-DD");
  url.searchParams.append("clientid", clientId);
  url.searchParams.append("agreementType", agreementTypeId);
  url.searchParams.append("clientDate", clientDate);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

/** get vehicle status count */
export const getVehicleStatusCount = async (
  { clientId, userId, vehicleTypeId, locationId },
  token
) => {
  const url = new URL(`${baseUrl}/Statistics/VehicleStatusCounts`);
  let clientDate = moment().format("YYYY-MM-DD");
  url.searchParams.append("clientid", clientId);
  url.searchParams.append("userId", userId);
  url.searchParams.append("vehicleType", vehicleTypeId);
  url.searchParams.append("clientDate", clientDate);

  if (locationId && locationId.length > 1) {
    locationId.map((item) => url.searchParams.append("MultipleLocation", item));
  } else if (locationId && locationId.length === 1) {
    url.searchParams.append("locationId", locationId[0]);
  } else {
    url.searchParams.append("locationId", 0);
  }

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getSalesStatuses = async (
  { clientId, userId, vehicleTypeId, locationId },
  token
) => {
  const url = new URL(`${baseUrl}/Statistics/Sales`);
  let clientDate = moment().format("YYYY-MM-DD");
  url.searchParams.append("clientid", clientId);
  url.searchParams.append("userId", userId);
  url.searchParams.append("vehicleType", vehicleTypeId);
  url.searchParams.append("clientDate", clientDate);

  if (locationId && locationId.length > 1) {
    locationId.map((item) => url.searchParams.append("MultipleLocation", item));
  } else if (locationId && locationId.length === 1) {
    url.searchParams.append("locationId", locationId[0]);
  } else {
    url.searchParams.append("locationId", 0);
  }

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};
