import React, { useEffect, useState, useContext } from "react";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { useHistory } from "react-router";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import { AuthContext } from "../auth/AuthProvider";
import * as api from "../api";
import { useTranslation } from "react-i18next";
import { Buffer } from "buffer";
import { makeStyles } from "@material-ui/core/styles";



const useStyles = makeStyles((theme) => ({
  iconButtonRoot: {
    backgroundColor: "inherit !important",
    color: "rgb(3, 25, 129)",
    padding: 0,
  },
}));

const GlobalSearch = () => {

  const classes = useStyles();
  const auth = useContext(AuthContext);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState({
    customers: [],
    vehicles: [],
    reservations: [],
    agreements: [],
  });
  const [selectedValue, setSelectedValue] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (
      results.customers.length > 0 ||
      results.vehicles.length > 0 ||
      results.reservations.length > 0 ||
      results.agreements.length > 0
    ) {
      setLoading(false);
    }
  }, [results]);

  const onChange = async (event) => {
    if (event.target.value.length >= 1) {
      let profile = auth.getProfile();

      try {
        let customers = await api.getCustomers(
          {
            Keyword: event.target.value,
            ClientId: profile.clientId,
          },
          auth.getToken()
        );

        setResults((results) => {
          return {
            ...results,
            customers: customers.list.map((c) => {
              return {
                id: c.CustomerId,
                label: `${c.FirstName} ${c.LastName}`,
                type: "customer",
              };
            }),
          };
        });
      } catch {
        setResults((results) => {
          return {
            ...results,
            customers: [],
          };
        });
      }

      try {
        let vehicles = await api.getVehicles(
          {
            LicenseNo: event.target.value,
            ClientId: profile.clientId,
          },
          auth.getToken()
        );

        setResults((results) => {
          return {
            ...results,
            vehicles: vehicles.list.map((c) => {
              return {
                id: c.VehicleId,
                label: `${c.Year} ${c.VehicleMakeName} ${c.ModelName}`,
                type: "vehicle",
              };
            }),
          };
        });
      } catch {
        setResults((results) => {
          return {
            ...results,
            vehicles: [],
          };
        });
      }

      try {
        let reservations = await api.getReservations(
          {
            ReservationNumber: event.target.value,
            ClientId: profile.clientId,
            UserId: profile.userId,
          },
          auth.getToken()
        );

        setResults((results) => {
          return {
            ...results,
            reservations: reservations.list.map((c) => {
              return {
                id: c.ReserveId,
                label: `${c.ReservationNumber} - ${c.FirstName} ${c.LastName} - ${c.VehicleNo}`,
                type: "reservation",
              };
            }),
          };
        });
      } catch {
        setResults((results) => {
          return {
            ...results,
            reservations: [],
          };
        });
      }

      try {
        let agreements = await api.getAgreements(
          {
            AgreementNumber: event.target.value,
            ClientId: profile.clientId,
            UserId: profile.userId,
          },
          auth.getToken()
        );

        setResults((results) => {
          return {
            ...results,
            agreements: agreements.list.map((c) => {
              return {
                id: c.AgreementId,
                label: `${c.AgreementNumber} - ${c.FirstName} ${c.LastName}`,
                type: "agreement",
              };
            }),
          };
        });
      } catch {
        setResults((results) => {
          return {
            ...results,
            agreements: [],
          };
        });
      }
    }
  };

  const handleItemClick = (value) => {
    setSelectedValue(value);

    setResults({
      customers: [],
      vehicles: [],
      reservations: [],
      agreements: [],
    });

    if (value) {
      const encodedId = Buffer.from(String(value.id)).toString("base64");
      if (value.type === "customer") {
        history.push({
          pathname: `/CustomerDetails/${encodedId}`,
          customerId: encodedId,
        });
      } else if (value.type === "vehicle") {
        history.push({
          pathname: `/VehicleDetails/${encodedId}`,
          vehicleId: encodedId,
        });
      } else if (value.type === "reservation") {
        history.push({
          pathname: `/ReservationDetails/${encodedId}`,
          reservationId: encodedId,
        });
      } else if (value.type === "agreement") {
        history.push({
          pathname: `/AgreementDetails/${encodedId}`,
          agreementId: encodedId,
        });
      }
    }
  };

  const options = [
    ...results.customers,
    ...results.vehicles,
    ...results.agreements,
    ...results.reservations,
  ];

  return (
    <Autocomplete
      freeSolo
      style={{ width: "80%" }}
      filterOptions={(x) => x}
      options={options}
      loading={loading}
      getOptionLabel={(option) => option.label}
      onInputChange={onChange}
      onChange={(e, value) => handleItemClick(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={t("labels.searchForAnything")}
          size="small"
          style={{ width: "100%" }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                <InputAdornment position="end">
                  <IconButton
                    aria-label="search"
                    onClick={(e) => handleItemClick(selectedValue)}
                    className={classes.iconButtonRoot}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
                   
    </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default GlobalSearch;
