export default {
    lookupLists: {
        locations: [],
        vehicleTypes: [],
        reservationTypes: [],
        reservationStatuses: [],
        agreementTypes: [],
        agreementStatuses: [],
        vehicleStatuses: [],
        users: [],
        reservationPlannerStatuses: [],
        agreementPlannerStatuses: [],
        vehicleTrackStatuses: [],
        make: [],
        states:[]
    },
    user: {},
    customer: {},
    vehicle: {},
    reservation: {},
    agreement: {},
    miscCharge: {
        taxes: []
    },
};
