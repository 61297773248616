import {
  SET_PERSONAL_INFORMATION,
  SET_PERSONAL_SEARCH_QUERY,
  SET_INSURANCE_INFORMATION,
  SET_RENTAL,
  SET_VEHICLES,
  SET_VEHICLE,
  SET_VEHICLE_TELEMETRY,
  SET_VEHICLE_SEARCH_QUERY,
  SET_DRIVER_SEARCH_QUERY,
  SET_DRIVERS_MAIN,
  SET_DRIVERS_SUB,
  DELETE_DRIVER,
  SET_REFERRAL,
  SET_BILLING,
  SET_AIRPORT,
  SET_OTHER,
  SET_RATE_TYPES,
  SET_RATES_AND_TAXES,
  SET_SUMMARY_INFO,
  GET_RATES,
  SET_RATES,
  SET_TAXES,
  SET_MISCELLANEOUS_CHARGES,
  SET_ADJUSTMENTS,
  SET_NOTES,
  RESET_ADD_RESERVATION,
  SET_ATTRIBUTES,
  SET_PLANNER_RENTAL,
  ENABLE_RATE_EDIT,
  SET_EQUIPMENT,
  SET_EXPENSE,
  RESET_RATES_AND_SUMMARY,
  SET_OPTIMAL_RATE_TYPE,
  ADD_API_CALL_IN_PROGRESS,
  SET_PACKAGE_RATES_TYPES,
  SET_PACKAGE,
} from "../types/addReservationTypes";

const initialState = {
  vehicles: [],
  rateTypes: [],
  summaryInfo: {},
  attributes: {},
  customerInformation: {
    currentStep: 1,
    personalSearchQuery: "",
    personalInformation: {},
    insuranceInformation: {},
  },
  rentalInformation: {
    currentStep: 1,
    vehicleSearchQuery: "",
    rental: {},
    vehicle: {},
    driver: {
      searchQuery: "",
      drivers: { main: [], sub: [] },
    },
    referral: {},
    billing: { same_as_customer: true },
    airport: {},
    other: {},
  },
  ratesAndTaxesInformation: {
    currentStep: 1,
    ratesAndTaxes: {},
    taxes: [],
    miscellaneousCharges: [],
    rates: {},
    adjustments: {},
    optimalRateType: null,
  },
  otherInformation: {
    currentStep: 1,
    notes: {},
    equipment: [],
    expense: {},
  },
  isEditRate: false,
  packageRatesTypes: [],
  selectedPackageRate: null,
};

const addReservationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PERSONAL_INFORMATION:
      return {
        ...state,
        customerInformation: {
          ...state.customerInformation,
          personalInformation: action.payload,
        },
      };
    case SET_PERSONAL_SEARCH_QUERY:
      return {
        ...state,
        customerInformation: {
          ...state.customerInformation,
          personalSearchQuery: action.payload,
        },
      };
    case SET_INSURANCE_INFORMATION:
      return {
        ...state,
        customerInformation: {
          ...state.customerInformation,
          insuranceInformation: action.payload,
        },
      };
    case SET_RENTAL:
      return {
        ...state,
        rentalInformation: {
          ...state.rentalInformation,
          rental: action.payload,
        },
        ratesAndTaxesInformation: {
          ...state.ratesAndTaxesInformation,
          miscellaneousCharges:
            state.ratesAndTaxesInformation.miscellaneousCharges.map((m) => {
              return {
                ...m,
                startDate: action.payload.check_out_date,
                endDate: action.payload.check_in_date,
              };
            }),
        },
      };
    case SET_PLANNER_RENTAL:
      return {
        ...state,
        rentalInformation: {
          ...state.rentalInformation,
          rental: {
            ...state.rentalInformation.rental,
            check_out_date: action.payload.startDate,
            check_in_date: action.payload.endDate,
            check_out_location: action.payload.location,
            check_in_location: action.payload.location,
          },
          vehicle: {
            ...state.rentalInformation.vehicle,
            vehicleRadio: action.payload.vehicleId ? "vehicle" : "vehicle_type",
            vehicle: {
              VehicleId: action.payload.vehicleId,
              VehicleMakeName: action.payload.vehicleName,
            },
            vehicle_type: {
              id: action.payload.vehicleTypeId,
              value: action.payload.vehicleTypeName,
            },
          },
        },
      };
    case SET_VEHICLES:
      return {
        ...state,
        vehicles: action.payload,
      };
    case SET_VEHICLE:
      return {
        ...state,
        rentalInformation: {
          ...state.rentalInformation,
          vehicle: action.payload,
        },
      };
    case SET_VEHICLE_TELEMETRY:
      return {
        ...state,
        rentalInformation: {
          ...state.rentalInformation,
          rental: {
            ...state.rentalInformation.rental,
            fuel_level_in: action.payload.fuel_level,
            odometer_in:
              action.payload.odometer >
              state.rentalInformation.vehicle.odometer_out
                ? action.payload.odometer
                : state.rentalInformation.vehicle.odometer_out,
          },
          vehicle: {
            ...state.rentalInformation.vehicle,
            fuel_level_out: action.payload.fuel_level,
            odometer_out: action.payload.odometer,
          },
        },
      };
    case SET_VEHICLE_SEARCH_QUERY:
      return {
        ...state,
        rentalInformation: {
          ...state.rentalInformation,
          vehicleSearchQuery: action.payload,
        },
      };
    case SET_DRIVER_SEARCH_QUERY:
      return {
        ...state,
        rentalInformation: {
          ...state.rentalInformation,
          driver: {
            ...state.rentalInformation.driver,
            searchQuery: action.payload,
          },
        },
      };
    case SET_DRIVERS_MAIN:
      return {
        ...state,
        rentalInformation: {
          ...state.rentalInformation,
          driver: {
            ...state.rentalInformation.driver,
            drivers: {
              ...state.rentalInformation.driver.drivers,
              main: [action.payload],
            },
          },
        },
      };
    case SET_DRIVERS_SUB:
      const isDuplicate = state.rentalInformation.driver.drivers.sub.find(
        (driver) => driver.customerId === action.payload.customerId
      );

      return {
        ...state,
        rentalInformation: {
          ...state.rentalInformation,
          driver: {
            ...state.rentalInformation.driver,
            drivers: {
              ...state.rentalInformation.driver.drivers,
              sub: isDuplicate
                ? [...state.rentalInformation.driver.drivers.sub]
                : [
                    ...state.rentalInformation.driver.drivers.sub,
                    action.payload,
                  ],
            },
          },
        },
      };
    case DELETE_DRIVER:
      return {
        ...state,
        rentalInformation: {
          ...state.rentalInformation,
          driver: {
            ...state.rentalInformation.driver,
            drivers: {
              ...state.rentalInformation.driver.drivers,
              sub: state.rentalInformation.driver.drivers.sub.filter(
                (driver) => driver.CustomerId !== action.payload
              ),
            },
          },
        },
      };
    case SET_REFERRAL:
      return {
        ...state,
        rentalInformation: {
          ...state.rentalInformation,
          referral: action.payload,
        },
      };
    case SET_BILLING:
      return {
        ...state,
        rentalInformation: {
          ...state.rentalInformation,
          billing: action.payload,
        },
      };
    case SET_AIRPORT:
      return {
        ...state,
        rentalInformation: {
          ...state.rentalInformation,
          airport: action.payload,
        },
      };
    case SET_OTHER:
      return {
        ...state,
        rentalInformation: {
          ...state.rentalInformation,
          other: action.payload,
        },
      };
    case SET_RATE_TYPES:
      return {
        ...state,
        rateTypes: action.payload,
      };
    case SET_RATES_AND_TAXES:
      return {
        ...state,
        ratesAndTaxesInformation: {
          ...state.ratesAndTaxesInformation,
          ratesAndTaxes: action.payload,
        },
      };
    case SET_SUMMARY_INFO:
      return {
        ...state,
        summaryInfo: action.payload,
        apiCallsInProgress: state.apiCallsInProgress - 1,
      };
    case GET_RATES:
      return {
        ...state,
        ratesAndTaxesInformation: {
          ...state.ratesAndTaxesInformation,
          rates: action.payload,
        },
      };
    case SET_RATES:
      return {
        ...state,
        ratesAndTaxesInformation: {
          ...state.ratesAndTaxesInformation,
          rates: {
            ...state.ratesAndTaxesInformation.rates,
            hourlyQty: action.payload.hourly_quantity,
            hourlyRate: action.payload.hourly_rate,
            weeklyQty: action.payload.weekly_quantity,
            weeklyRate: action.payload.weekly_rate,
            halfDayQty: action.payload.half_daily_quantity,
            halfDayRate: action.payload.half_daily_rate,
            monthlyQty: action.payload.monthly_quantity,
            monthlyRate: action.payload.monthly_rate,
            dailyQty: action.payload.daily_quantity,
            dailyRate: action.payload.daily_rate,
            weekendDailyQty: action.payload.weekend_daily_quantity,
            weekendDayRate: action.payload.weekend_daily_rate,
            kMorMileageCharge: action.payload.miles_charge,
            weeklyKMorMileageAllowed: action.payload.weekly_miles_allowed,
            dailyKMorMileageAllowed: action.payload.daily_miles_allowed,
            monthlyKMorMileageAllowed: action.payload.monthly_miles_allowed,
            extraHourlyQty: action.payload.extra_hourly_quantity,
            extraHourlyRate: action.payload.extra_hourly_rate,
            extraDailyQty: action.payload.extra_daily_quantity,
            extraDailyRate: action.payload.extra_daily_rate,
            extraWeeklyQty: action.payload.extra_weekly_quantity,
            extraWeeklyRate: action.payload.extra_weekly_rate,
            fuelCharge: action.payload.fuel_charge,
            packageRate: action.payload.package_rate,
            monRate: action.payload.mon_rate,
            monCount: action.payload.mon_quantity,
            tuesRate: action.payload.tues_rate,
            tuesCount: action.payload.tues_quantity,
            wedRate: action.payload.wed_rate,
            wedCount: action.payload.wed_quantity,
            thursRate: action.payload.thurs_rate,
            thursCount: action.payload.thurs_quantity,
            friRate: action.payload.fri_rate,
            friCount: action.payload.fri_quantity,
            satRate: action.payload.sat_rate,
            satCount: action.payload.sat_quantity,
            sunRate: action.payload.sun_rate,
            sunCount: action.payload.sun_quantity,
            // isDayWeek: action.payload.isDayWeek,
          },
        },
      };
    case SET_TAXES:
      return {
        ...state,
        ratesAndTaxesInformation: {
          ...state.ratesAndTaxesInformation,
          taxes: action.payload,
        },
      };
    case SET_MISCELLANEOUS_CHARGES:
      return {
        ...state,
        ratesAndTaxesInformation: {
          ...state.ratesAndTaxesInformation,
          miscellaneousCharges: action.payload,
        },
      };
    case SET_ADJUSTMENTS:
      return {
        ...state,
        ratesAndTaxesInformation: {
          ...state.ratesAndTaxesInformation,
          adjustments: action.payload,
        },
      };
    case SET_NOTES:
      return {
        ...state,
        otherInformation: {
          ...state.otherInformation,
          notes: { ...state.otherInformation.notes, ...action.payload },
        },
      };
    case SET_EXPENSE:
      return {
        ...state,
        otherInformation: {
          ...state.otherInformation,
          expense: { ...state.otherInformation.expense, ...action.payload },
        },
      };
    case SET_EQUIPMENT:
      return {
        ...state,
        otherInformation: {
          ...state.otherInformation,
          equipment: action.payload,
        },
      };
    case SET_ATTRIBUTES:
      return {
        ...state,
        attributes: action.payload,
      };
    case RESET_ADD_RESERVATION:
      return {
        vehicles: [],
        rateTypes: [],
        summaryInfo: {},
        attributes: {},
        customerInformation: {
          currentStep: 1,
          personalSearchQuery: "",
          personalInformation: {},
          insuranceInformation: {},
        },
        rentalInformation: {
          currentStep: 1,
          vehicleSearchQuery: "",
          rental: {},
          vehicle: {},
          driver: {
            searchQuery: "",
            drivers: { main: [], sub: [] },
          },
          referral: {},
          billing: { same_as_customer: true },
          airport: {},
          other: {},
        },
        ratesAndTaxesInformation: {
          currentStep: 1,
          ratesAndTaxes: {},
          taxes: [],
          miscellaneousCharges: [],
          rates: {},
          adjustments: {},
        },
        otherInformation: {
          currentStep: 1,
          notes: {},
          equipment: [],
          expense: {},
        },
        packageRatesTypes: [],
      };
    case ENABLE_RATE_EDIT:
      return {
        ...state,
        isEditRate: action.payload,
      };
    case RESET_RATES_AND_SUMMARY:
      return {
        ...state,
        rateTypes: [],
        summaryInfo: {},
        rentalInformation: {
          ...state.rentalInformation,
          rental: { ...state.rentalInformation.rental, reservation_type: "" },
        },
        ratesAndTaxesInformation: {
          ...state.ratesAndTaxesInformation,
          rates: action.payload?.isResetRates === true ? {} : action.payload,
        },
      };
    case SET_OPTIMAL_RATE_TYPE:
      return {
        ratesAndTaxesInformation: {
          ...state.ratesAndTaxesInformation,
          optimalRateType: action.payload,
        },
      };
    case ADD_API_CALL_IN_PROGRESS:
      return {
        ...state,
        apiCallsInProgress: (state.apiCallsInProgress ?? 0) + 1,
      };
    case SET_PACKAGE_RATES_TYPES:
      return {
        ...state,
        packageRatesTypes: action.payload,
      };
    case SET_PACKAGE:
      return {
        ...state,
        selectedPackageRate: action.payload,
      };
    default:
      return state;
  }
};

export default addReservationReducer;
