import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const getTodos = ({ clientId, userId, startDate, endDate }, token) => {
  const url = new URL(`${baseUrl}/Todos`);

  clientId && url.searchParams.append("clientId", clientId);
  userId && url.searchParams.append("userId", userId);
  startDate && url.searchParams.append("startDate", startDate);
  endDate && url.searchParams.append("endDate", endDate);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options)
    .then((res) => res.json())
    .catch(handleError);
};

export const getTodoByID = (clientId, userId, toDoId, token) => {
  const url = new URL(`${baseUrl}/Todos/${toDoId}`);

  clientId && url.searchParams.append("clientId", clientId);
  userId && url.searchParams.append("userId", userId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getVehicleTodos = ({ clientId, userId, isAll }, token) => {
  const url = new URL(`${baseUrl}/Todos/Vehicles`);

  url.searchParams.append("clientid", clientId);
  url.searchParams.append("userId", userId);
  url.searchParams.append("IsAllRecord", isAll);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const addTodo = async (values, token) => {
  const response = await fetch(`${baseUrl}/Todos`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(values),
  })
    .then(handleResponse)
    .catch(handleError);

  return { ...response, status: true };
};

export const getTodosSummary = (
  {
    clientId,
    userId,
    locationId,
    referenceId,
    referenceType,
    startDate,
    endDate,
  },
  token
) => {
  const url = new URL(`${baseUrl}/Todos/ToDoSummary`);

  clientId && url.searchParams.append("clientid", clientId);
  userId && url.searchParams.append("userId", userId);
  locationId && url.searchParams.append("locationId", locationId);
  referenceId && url.searchParams.append("referenceId", referenceId);
  referenceType && url.searchParams.append("referenceType", referenceType);
  startDate && url.searchParams.append("startDate", startDate);
  endDate && url.searchParams.append("endDate", endDate);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options)
    .then((res) => res.json())
    .catch(handleError);
};

export const getTodosAgreement = (
  clientId,
  userId,
  startDate,
  endDate,
  locationId,
  token
) => {
  const url = new URL(`${baseUrl}/Todos/ToDoAgreement`);

  url.searchParams.append("clientId", clientId);
  url.searchParams.append("userId", userId);
  url.searchParams.append("startDate", startDate);
  url.searchParams.append("endDate", endDate);
  url.searchParams.append("locationId", locationId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getTodosReservations = (
  clientId,
  userId,
  startDate,
  endDate,
  locationId,
  token
) => {
  const url = new URL(`${baseUrl}/Todos/ToDoReservation`);

  url.searchParams.append("clientId", clientId);
  url.searchParams.append("userId", userId);
  url.searchParams.append("startDate", startDate);
  url.searchParams.append("endDate", endDate);
  url.searchParams.append("locationId", locationId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const putTodoDetails = async (
  id,
  clientId,
  date,
  name,
  priority,
  notes,
  assignedTo,
  status,
  userId,
  referenceType,
  referenceId,
  referenceNumber,
  token
) => {
  const url = new URL(`${baseUrl}/Todos`);

  url.searchParams.append("id", id);
  url.searchParams.append("clientId", clientId);
  url.searchParams.append("date", date);
  url.searchParams.append("name", name);
  url.searchParams.append("priority", priority);
  url.searchParams.append("notes", notes);
  url.searchParams.append("assignedTo", assignedTo);
  url.searchParams.append("status", status);
  url.searchParams.append("userId", userId);
  url.searchParams.append("referenceType", referenceType);
  url.searchParams.append("referenceId", referenceId);
  url.searchParams.append("referenceNumber", referenceNumber);

  let options = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response.text())
    .catch(handleError);
};

export const deleteTodoDetails = async (toDoId, clientId, userID, token) => {
  const url = new URL(`${baseUrl}/Todos/${toDoId}`);

  url.searchParams.append("clientId", clientId);
  url.searchParams.append("userID", userID);

  let options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const putTodoAssignee = async (referenceId, type, assignTo, token) => {
  const url = new URL(`${baseUrl}/Todos/Assignee`);

  url.searchParams.append("referenceId", referenceId);
  url.searchParams.append("type", type);
  url.searchParams.append("assignTo", assignTo);

  let options = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const putTodoExpiryDate = async (
  vehicleId,
  expiryType,
  expiryDate,
  userId,
  token
) => {
  const url = new URL(`${baseUrl}/Vehicles/${vehicleId}/Expiry/${expiryType}`);

  url.searchParams.append("expiryDate", expiryDate);
  url.searchParams.append("userId", userId);

  let options = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};
