import { useSelector } from "react-redux";

export const usePermission = (permission) => {
    const user = useSelector((state) => state.user);
    return user.permissions?.includes(permission);
};

export const useFeature = (feature) => {
    const features = useSelector((state) => state.lookupLists.features);
    return features?.map((f) => f.featureName).includes(feature);
};
