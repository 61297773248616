import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const getInsuranceCompanies = async (clientId, token) => {
  const url = new URL(`${baseUrl}/InsuranceCompanies`);

  url.searchParams.append("clientid", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getInsuranceCompanyById = async (clientId, insuranceId, token) => {
  const url = new URL(`${baseUrl}/InsuranceCompanies/${insuranceId}`);

  url.searchParams.append("ClientId", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const postInsuranceDetails = async (body, token) => {
  const url = new URL(`${baseUrl}/InsuranceCompanies`);

  let options = {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options)
    .then((response) => response.text())
    .catch(handleError);
};

export const updateInsuranceDetails = async (insuranceId, data, token) => {
  const url = new URL(`${baseUrl}/InsuranceCompanies/${insuranceId}`);

  let options = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const deleteInsuranceDetails = async (clientId, insuranceId, token) => {
  const url = new URL(`${baseUrl}/InsuranceCompanies/${insuranceId}`);

  url.searchParams.append("clientId", clientId);

  let options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  return fetch(url, options)
    .then((response) => response.text())
    .catch(handleError);
};
