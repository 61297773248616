/* /src/routes/privateRoute.jsx */
import React from "react";
import { Route } from "react-router-dom";
import { AuthConsumer } from "./AuthProvider";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

export const PrivateRoute = ({ component, ...rest }) => {
  const renderFn = (Component) => (props) =>
    (
      <AuthConsumer>
        {({ isAuthenticated, signinRedirect }) => {
          if (!!Component && isAuthenticated()) {
            return <Component {...props} />;
          } else {
            signinRedirect();
            return (
              <Backdrop open={true}>
                <CircularProgress color="primary" size={60} />
              </Backdrop>
            );
          }
        }}
      </AuthConsumer>
    );

  return <Route {...rest} render={renderFn(component)} />;
};
