import React, { useState, useEffect, useContext } from "react";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "../DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Switch,
  Tooltip,
  Checkbox,
  Box,
  FormControlLabel,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { AuthContext } from "../../../../auth/AuthProvider";
import { getLocations } from "../../../../api/locationApi";
import { getUserByID, getLanguage } from "../../../../api/usersApi";
import { searchUserRoles } from "../../../../api/rolesApi";
import CheckBoxModal from "../Permission/CheckBoxModal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useFeature, usePermission } from "../../../../auth/permissions";
import { usePhoneNumberFormat } from "../../../../services/usePhoneNumberFormat";
import {
  GetMaximumUsers,
  GetActiveUsersCount
} from "../../../../api/usersApi";
const useStyles = makeStyles((theme) => ({
  content: {
    margin: "auto",
  },
  field: {
    marginBottom: theme.spacing(2),
  },
}));

export default function AddUser(props) {
  const [MaximumUsersCount, SetMaximumUsersCount] = useState(0);
  const [ActiveUsersCount, SetActiveUsersCount] = useState(0);
  const classes = useStyles();
  const { handleClose, open, save, head, idValue, loading } = props;
  const auth = useContext(AuthContext);
  const profile = auth.getProfile();
  const { t } = useTranslation();
  const [userData, setUserData] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [preDefinedRoles, setPreDefinedRoles] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const edit = !usePermission("VIEW_USER_GRAB");
  const canEdit = usePermission("VIEW_ADMIN_TAB") && edit;
  const { newPhoneNoPattern } = usePhoneNumberFormat();
  const p = newPhoneNoPattern();
  const [user, setUser] = useState({
    userName: "",
    password: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    scanAccKey: "",
    active: true,
    lock: false,
    isReservation: true,
    language: {
      key: "",
      value: "",
    },
    userRole: {
      userRoleID: 0,
      roleName: "",
    },
  });

  const phoneRegExp =
    /^[\+]?([(]?\+?[/0-9]{1,3}[)]?)?[\s.-]?([(]?[/0-9]{3}[)]?)[\s.-]?([(]?[/0-9]{3,4}[)]?)[\s.-]?([(]?[/0-9]{2,4}[)]?)$/;

  let validationShape = {
    userName: yup.string().required(t("messages.required")),
    password: yup.string(),
    // password: yup
    //   .string()
    //   .required(t("messages.required"))
    //   .matches(
    //     /(?=.*[a-z])(?=.*[A-Z])\w+/,
    //     t("messages.passwordUpperLowerCase")
    //   )
    //   .matches(/\d/, t("messages.passowrdNumberCase"))
    //   .matches(
    //     /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
    //     t("messages.passowrdSpecialCharacterCase")
    //   )
    //   .min(8, t("messages.passwordMinimum8Characters")),

    firstName: yup.string().required(t("messages.required")),
    lastName: yup.string().required(t("messages.required")),
    email: yup
      .string()
      .email(t("messages.emailIsNotValid"))
      .required(t("messages.required")),
    phoneNo: yup.string().matches(p, {
      message: t("messages.phoneInvalid"),
      excludeEmptyString: true,
    }),
    language: yup.string(),
    scanAccKey: yup.string(),
    userRole: yup.string().required(t("messages.required")),
  };

  if (idValue > 0) {
    validationShape = {
      userName: yup.string(),
      password: yup.string(),
      firstName: yup.string().required(t("messages.required")),
      lastName: yup.string().required(t("messages.required")),
      email: yup.string().when("$canEdit", {
        is: true,
        then: yup
          .string()
          .email(t("messages.emailIsNotValid"))
          .required(t("messages.required")),
      }),
      phoneNo: yup.string().matches(p, {
        message: t("messages.phoneInvalid"),
        excludeEmptyString: true,
      }),
      language: yup.string(),
      scanAccKey: yup.string(),
      userRole: yup.string().when("$canEdit", {
        is: true,
        then: yup.string().required(t("messages.required")),
      }),
    };
  }

  const schema = yup.object().shape(validationShape);

  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    context: { canEdit },
  });

  const getUserDetails = async () => {
    if (idValue > 0) {
      let details = await getUserByID(
        profile.clientId,
        idValue,
        auth.getToken()
      );
      setUserData(details);
    } else {
      setUserData(null);
    }
  };

  useEffect(() => {
    open && getUserDetails();
    open && getLocationList();
  }, [open]);

  useEffect(() => {
    if (idValue > 0) {
      setUser((user) => ({
        ...user,
        userName: userData?.userName,
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        email: userData?.email,
        phoneNo: userData?.phone,
        scanAccKey: userData?.scanAccessKey,
        active: userData?.isActive,
        lock: userData?.lockOut,
        isReservation: userData?.isReservationEmail,
        language: {
          key: userData?.language,
          value: userData?.languageName,
        },
        userRole: {
          userRoleID: userData?.userRoleID,
          roleName: userData?.roleName,
        },
      }));

      getFilteredLocations(userData?.locationList);
    }
  }, [userData]);

  const getLocationList = async () => {
    getLocations(profile.clientId, profile.userId, auth.getToken(), true).then(
      (res) => {
        let response = res.map(({ locationId, locationName }) => ({
          locationId,
          locationName,
        }));

        setLocationList(response);
      }
    );
  };

  const getUserRoleList = async () => {
    await searchUserRoles(profile.clientId, auth.getToken())
      .then((roleList) => {
        let role = roleList?.map(({ userRoleID, roleName }) => ({
          userRoleID,
          roleName,
        }));

        setPreDefinedRoles(role);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserRoleList();
  }, [setPreDefinedRoles]);

  const getLanguageList = async () => {
    getLanguage(auth.getToken()).then((res) => {
      let response = res.map(({ key, value }) => ({
        key,
        value,
      }));

      setLanguageList(response);
    });
    getUserCount();
    getUsersActiveCount();
  };

  useEffect(() => {
    getLanguageList();
  }, [setLanguageList]);

  const onSubmit = async (data) => {
    const { userName, language, userRole, email } = user;

    let details = {
      ...data,
      userName,
      language,
      userRole,
      selectedLocations,
      email,
    };

    let result = await save(details);

    if (result) {
      closeDialogBox();
    }
  };

  const closeDialogBox = () => {
    setUser((user) => ({
      ...user,
      userName: "",
      password: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNo: "",
      scanAccKey: "",
      active: true,
      lock: false,
      isReservation: true,
      language: {
        key: "",
        value: "",
      },
      userRole: {
        userRoleID: 0,
        roleName: "",
      },
    }));

    setSelectedLocations([]);

    handleClose();
  };

  const [selectedLocations, setSelectedLocations] = useState([]);

  const getFilteredLocations = (locationList) => {
    let list = [];
    if (locationList) {
      for (let index = 0; index < locationList.length; index++) {
        const data = locationList[index];

        list[index] = data.locationId;
      }
    }

    setSelectedLocations(list);
  };

  const handleCheckBoxes = (event) => {
    if (event.target.checked === true) {
      setSelectedLocations([...selectedLocations, parseInt(event.target.id)]);
    } else {
      setSelectedLocations(
        selectedLocations.filter(
          (word) => parseInt(word) !== parseInt(event.target.id)
        )
      );
    }
  };

  const getCheckBox = (functionID, handleCheckBoxes, description) => {
    return (
      <CheckBoxModal
        name={functionID}
        propChange={handleCheckBoxes}
        list={selectedLocations}
        labelText={description}
        disabled={!canEdit}
      />
    );
  };

  const [passwordMessage, setPasswordMessage] = useState("");
  const onChangeUserData = (e) => {
    let passwordError = "";
    setUser((user) => ({
      ...user,
      [e.target.name]: e.target.value,
    }));

    //validation for password on change event
    if (e.target.name == "password") {
      //check string is empty or not
      if (e.target.value == "") {
        passwordError = t("messages.required");
        setPasswordMessage(passwordError);
        return;
      }
      //check string contains minimum 8 characters
      else if (e.target.value.length < 8) {
        passwordError = t("messages.passwordMinimum8Characters");
        setPasswordMessage(passwordError);
        return;
      }
      //check string contains at least one lower or upper case
      else if (!checkUpperLower(e.target.value)) {
        passwordError = t("messages.passwordUpperLowerCase");
        setPasswordMessage(passwordError);
        return;
      }
      //check string contains at least one number
      else if (!checkDigit(e.target.value)) {
        passwordError = t("messages.passowrdNumberCase");
        setPasswordMessage(passwordError);
        return;
      }
      // check string contains at least one special character
      else if (!checkSpecialCharacter(e.target.value)) {
        passwordError = t("messages.passowrdSpecialCharacterCase");
        setPasswordMessage(passwordError);
        return;
      }
      //all okay
      else {
        passwordError = "";
        setPasswordMessage(passwordError);
        return;
      }
    }
  };

  function checkUpperLower(string) {
    let upper = false,
      lower = false;

    for (const character of string) {
      if (character.toUpperCase() === character.toLowerCase()) continue;
      upper ||= character === character.toUpperCase();
      lower ||= character === character.toLowerCase();
      if (upper && lower) return true;
    }
    return false;
  }

  function checkDigit(string) {
    if (string.match(/\d/)) {
      return true;
    } else {
      return false;
    }
  }

  function checkSpecialCharacter(string) {
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    if (format.test(string)) {
      return true;
    } else {
      return false;
    }
  }

  const onChangeSwitchData = (e) => {
    setUser((user) => ({
      ...user,
      [e.target.name]: e.target.checked,
    }));
  };

  const getUserCount = async () => {
    try {
      
      const data = await GetMaximumUsers(profile.clientId, auth.getToken());
      SetMaximumUsersCount(data);
      return data; // Return the fetched data
    } catch (error) {
   
      throw error; // Throw the error to be handled by the caller
    }
  };

  const getUsersActiveCount = async () => {
    try {
      
      const data = await GetActiveUsersCount(profile.clientId, auth.getToken());
      SetActiveUsersCount(data);
      return data; // Return the fetched data
    } catch (error) {
   
      throw error; // Throw the error to be handled by the caller
    }
  };

  

  return (
    <div>
      <Dialog
        onClose={closeDialogBox}
        open={open}
        className={classes.content}
        scroll="paper"
        maxWidth="md"
      >
        <DialogTitle onClose={closeDialogBox} header= {`${t("tabs.allowedusers")}: ${MaximumUsersCount} ${t("tabs.Activeusers")}: ${ActiveUsersCount}`}>{head}   </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Tooltip title={t("labels.userNameToolTip")} arrow>
                  <TextField
                    name="userName"
                    label={`${t("labels.userName")} *`}
                    fullWidth
                    type="text"
                    variant="outlined"
                    size="small"
                    value={user.userName || ""}
                    onChange={onChangeUserData}
                    inputRef={register}
                    error={!!errors.userName}
                    helperText={errors.userName?.message}
                    disabled={idValue > 0 ? true : false}
                  />
                </Tooltip>
              </Grid>

              {idValue == 0 && (
                <Grid item md={6} xs={12}>
                  <TextField
                    required
                    name="password"
                    label={`${t("labels.password")}`}
                    fullWidth
                    type="password"
                    variant="outlined"
                    size="small"
                    value={user.password || ""}
                    onChange={onChangeUserData}
                    inputRef={register}
                    // error={!!errors.password}
                    // helperText={errors.password?.message}
                    error={!!passwordMessage}
                    helperText={passwordMessage}
                  />
                </Grid>
              )}

              <Grid item md={6} xs={12}>
                <TextField
                  name="firstName"
                  label={`${t("labels.firstName")} *`}
                  fullWidth
                  type="text"
                  variant="outlined"
                  size="small"
                  value={user.firstName || ""}
                  onChange={onChangeUserData}
                  inputRef={register}
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  name="lastName"
                  label={`${t("labels.lastName")} *`}
                  fullWidth
                  type="text"
                  variant="outlined"
                  size="small"
                  value={user.lastName || ""}
                  onChange={onChangeUserData}
                  inputRef={register}
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  name="email"
                  label={`${t("labels.email")} *`}
                  fullWidth
                  type="text"
                  variant="outlined"
                  size="small"
                  disabled={!canEdit}
                  value={user.email || ""}
                  onChange={onChangeUserData}
                  inputRef={register}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  name="phoneNo"
                  label={`${t("labels.phoneNo")}`}
                  fullWidth
                  type="text"
                  variant="outlined"
                  size="small"
                  value={user.phoneNo || ""}
                  onChange={onChangeUserData}
                  inputRef={register}
                  error={!!errors.phoneNo}
                  helperText={errors.phoneNo?.message}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Autocomplete
                  onChange={(event, newValue) => {
                    setUser((user) => ({
                      ...user,
                      language: newValue,
                    }));
                  }}
                  size="small"
                  options={languageList}
                  getOptionLabel={(option) => option?.value || ""}
                  getOptionSelected={(option) => option?.value || ""}
                  value={user.language}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="language"
                      label={`${t("labels.language")}`}
                      variant="outlined"
                      inputRef={register}
                      error={!!errors.language}
                      helperText={errors.language?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  name="scanAccKey"
                  label={`${t("labels.scanAccessKey")}`}
                  fullWidth
                  type="text"
                  variant="outlined"
                  size="small"
                  value={user.scanAccKey || ""}
                  onChange={onChangeUserData}
                  inputRef={register}
                  error={!!errors.scanAccKey}
                  helperText={errors.scanAccKey?.message}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Autocomplete
                  onChange={(event, newValue) => {
                    setUser((user) => ({
                      ...user,
                      userRole: newValue,
                    }));
                  }}
                  size="small"
                  options={preDefinedRoles}
                  getOptionLabel={(option) => option?.roleName || ""}
                  getOptionSelected={(option) => option?.roleName || ""}
                  value={user.userRole}
                  disabled={!canEdit}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="userRole"
                      label={`${t("labels.roles")} *`}
                      variant="outlined"
                      inputRef={register}
                      error={!!errors.userRole}
                      helperText={errors.userRole?.message}
                    />
                  )}
                />
              </Grid>

              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    style={{ fontWeight: 600 }}
                  >
                    {`${t("labels.locations")} *`}
                  </Typography>
                </Grid>
                {locationList?.map((location, i) => {
                  return (
                    <Grid item md={6} xs={12} key={i}>
                      {getCheckBox(
                        location.locationId,
                        handleCheckBoxes,
                        location.locationName
                      )}
                    </Grid>
                  );
                })}
              </Grid>

              <Grid item xs={6}>
                <Tooltip title={t("labels.userActiveToolTip")} arrow>
                  <FormControlLabel
                    control={
                      <Switch
                        name="active"
                        checked={user.active || false}
                        onChange={onChangeSwitchData}
                        inputRef={register}
                        color="primary"
                        inputProps={{ "aria-label": "checkbox" }}
                      />
                    }
                    labelPlacement="start"
                    label={
                      <Typography
                        color="textSecondary"
                        variant="body1"
                        display="inline"
                      >
                        {t("labels.active")}
                      </Typography>
                    }
                  />
                </Tooltip>
              </Grid>

              <Grid item xs={6}>
                <Tooltip title={t("labels.userLockToolTip")} arrow>
                  <FormControlLabel
                    control={
                      <Switch
                        name="lock"
                        checked={user.lock || false}
                        onChange={onChangeSwitchData}
                        inputRef={register}
                        color="primary"
                        inputProps={{ "aria-label": "checkbox" }}
                      />
                    }
                    labelPlacement="start"
                    label={
                      <Typography
                        color="textSecondary"
                        variant="body1"
                        display="inline"
                      >
                        {t("labels.lock")}
                      </Typography>
                    }
                  />
                </Tooltip>
              </Grid>

              <Grid item sm={6} xs={12}>
                <Tooltip title={t("labels.isReservationEmailToolTip")} arrow>
                  <FormControlLabel
                    control={
                      <Switch
                        name="isReservation"
                        checked={user.isReservation || false}
                        onChange={onChangeSwitchData}
                        inputRef={register}
                        color="primary"
                        inputProps={{ "aria-label": "checkbox" }}
                        disabled={!canEdit}
                      />
                    }
                    labelPlacement="start"
                    label={
                      <Typography
                        color="textSecondary"
                        variant="body1"
                        display="inline"
                      >
                        {t("labels.isReservationEmail")}
                      </Typography>
                    }
                  />
                </Tooltip>
              </Grid>

              <Grid item xs={12} className={classes.field}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  disabled={loading || selectedLocations.length == 0}
                >
                  {t("buttons.save")}
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </form>
      </Dialog>
    </div>
  );
}
