import * as types from "../types";
import initialState from "../initialState";

const userReducer = (state = initialState.user, { type, user }) => {
  switch (type) {
    case types.LOAD_USER_SUCCESS:
      return {
        ...state,
        ...user,
      };
      case types.LOAD_CLIENT_DETAILS_SUCCESS:
      return {
        ...state,
        clientDetails: user,
      };
    default:
      return state;
  }
};

export default userReducer;
