import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./i18n";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./auth/AuthProvider";
import store from "./redux/configureStore";
import { Provider as ReduxProvider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { registerLicense } from "@syncfusion/ej2-base";

// Registering Syncfusion license key
registerLicense(
  "Mgo+DSMBaFt/QHRqVVhkVFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jSn5UdkBgXHtadHRWQQ==;Mgo+DSMBPh8sVXJ0S0J+XE9AflRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TdUVkWXpddXBRQmJcVg==;ORg4AjUWIQA/Gnt2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkZjXn9bc3FRQmRYUUM=;MTI3MDc3M0AzMjMwMmUzNDJlMzBiMGlhZCtUZUphK3hDNEdWOWdhdkdvZkswRXBoTGhjS0lwd01mRC9UNk1JPQ==;MTI3MDc3NEAzMjMwMmUzNDJlMzBBOVNCZ2d0MXcwVmNadGUwaUNYanFPTnQrUTd6SGZQazh3V0pJVk1rTkxFPQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFtKVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUVgWXhedXdQQmRZV0Vx;MTI3MDc3NkAzMjMwMmUzNDJlMzBVbDgrcmRTbGZ2SjhicDhnSXA5ZUluOUlUTzYyc2ZiYnNhMmpBRzlRTnVNPQ==;MTI3MDc3N0AzMjMwMmUzNDJlMzBBbjJPUXowZW9mLzBSczlxMEdqUUlyNUZiOVhNL212YUM3UVE4a3RUU3lJPQ==;Mgo+DSMBMAY9C3t2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkZjXn9bc3FRQmdcWUw=;MTI3MDc3OUAzMjMwMmUzNDJlMzBmTFlrVko0Yy9EakI4VFRPYjNGZFlNSVA4Z1ZCY0lqbHBJaWwrbGVOeG1FPQ==;MTI3MDc4MEAzMjMwMmUzNDJlMzBNREZNRjgvQUR2Y1hLS1hyWnBERFRPdGd1VGc3bTEwRDJ6NnRxbFZKY1prPQ==;MTI3MDc4MUAzMjMwMmUzNDJlMzBVbDgrcmRTbGZ2SjhicDhnSXA5ZUluOUlUTzYyc2ZiYnNhMmpBRzlRTnVNPQ=="
);

let persistor = persistStore(store);

ReactDOM.render(
  <ReduxProvider store={store}>
    <AuthProvider>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </AuthProvider>
  </ReduxProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
