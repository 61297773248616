import React, { useMemo, useContext, useState } from "react";
import { Paper, Dialog, DialogContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import DialogTitle from "../Admin/ApplicationConfig/DialogTitle";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import ReactWebChat, { createDirectLine } from "botframework-webchat";
import { AuthContext } from "../../auth/AuthProvider.js";
import Draggable from "react-draggable";

const useStyles = makeStyles((theme) => ({
  content: {
    width: "80%",
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "85%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "95%",
    },
  },
  logo: {
    position: "absolute",
    paddingBottom: 5,
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function ChatBotModal(props) {
  const classes = useStyles();
  const { handleClose, open } = props;
  const auth = useContext(AuthContext);
  const profile = auth.getProfile();
  const [contentStyle, setContentStyle] = useState({
    height: "41rem",
    overflowY: "hidden",
  });

  const { t } = useTranslation();

  const directLine = useMemo(
    () =>
      createDirectLine({
        token: "EIjtx-itx4M.d-Bf4TVBOihmTVaGsqrRb8TCDKuqI2HsjO0hWzQP11Q",
      }),
    []
  );

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      PaperComponent={PaperComponent}
      hideBackdrop={true}
      disableEnforceFocus={true}
      style={{ pointerEvents: "none" }}
      PaperProps={{ style: { pointerEvents: "auto" } }}
    >
      <DialogTitle
        onClose={handleClose}
        onMinimize={() =>
          setContentStyle((contentStyle) =>
            contentStyle.height === "41rem"
              ? { height: "0rem", padding: "0px" }
              : { height: "41rem", overflowY: "hidden" }
          )
        }
        icon={
          <ChatBubbleOutlineIcon className={classes.logo} color="primary" />
        }
        style={{ cursor: "move" }}
        id="draggable-dialog-title"
      >
        {t("headings.chatbot")}
      </DialogTitle>

      <DialogContent dividers style={contentStyle}>
        <ReactWebChat
          directLine={directLine}
          userID={`${profile.userId}@${profile.clientId}`}
        />
        ,
      </DialogContent>
    </Dialog>
  );
}
