import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const getReferralNames = async (clientId, token) => {
  const url = new URL(`${baseUrl}/Referral/ReferralNames`);

  url.searchParams.append("ClientId", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const saveReferral = async (values, token) => {
  const response = await fetch(`${baseUrl}/Referral`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(values),
  })
    .then((response) => response.text())
    .catch(handleError);

  return { ...response };
};

export const getReferralById = async (id, clientId, token) => {
  const url = new URL(`${baseUrl}/Referral/${id}`);

  url.searchParams.append("ClientId", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getReferralDetails = async (clientId, token) => {
  const url = new URL(`${baseUrl}/Referral`);

  url.searchParams.append("Clientid", clientId);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getReferralDetailsById = async (referralId, token) => {
  const url = new URL(`${baseUrl}/Referral/${referralId}`);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const saveReferralDetails = async (data, token) => {
  const url = new URL(`${baseUrl}/Referral`);

  let options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options)
    .then((response) => response.text())
    .catch(handleError);
};

export const updateReferralDetails = async (referralId, data, token) => {
  const url = new URL(`${baseUrl}/Referral/${referralId}`);

  let options = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options)
    .then((response) => response)
    .catch(handleError);
};

export const getReferralByIdAdmin = async (clientId, token, paymentId) => {
  const url = new URL(`${baseUrl}/Referral/${paymentId}/ReferralPayment`);
  url.searchParams.append("ClientId", clientId);
  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const searchReferralPayments = async (filterValues, token) => {
  const url = new URL(`${baseUrl}/Referral/ReferralPayments`);
  for (var key in filterValues) {
    if (
      filterValues.hasOwnProperty(key) &&
      filterValues[key] !== "" &&
      filterValues[key] !== null
    ) {
      url.searchParams.append(key, filterValues[key]);
    }
  }
  //url.searchParams.append('Active', 'true');
  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const updateReferralPayment = async (id, data, token) => {
  const url = new URL(`${baseUrl}/Referral/${id}/ReferralPayment`);
  let options = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options)
    .then((response) => response.text())
    .catch(handleError);
};

export const deleteReferral = async (referralId, userId, token) => {
  const url = new URL(`${baseUrl}/Referral/${referralId}/ReferralPayment`);

  url.searchParams.append("userId", userId);

  let options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options)
    .then((res) => res)
    .catch(handleError);
};
